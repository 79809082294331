import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable, Subject, of, throwError } from 'rxjs';
import { LogixapiService } from '../../../../services/logixapi.service';
import { AddressService } from '../../../../services/address.service';
import {
  catchError,
  map,
  finalize,
  takeUntil,
  tap,
  debounceTime,
  distinctUntilChanged,
  merge,
  switchMap,
} from 'rxjs/operators';
//import * as camelCase from 'camelcase-keys';
import * as humps from 'humps';
console.log(humps);

const DEFAULT_ADDRESS_MODEL = {
  addressFinderId: null,
  addressLine1: null,
  addressLine2: null,
  boxIdentifier: null,
  boxType: null,
  canonicalAddressId: null,
  countryCode: null,
  deleted: false,
  description: null,
  fullAddress: null,
  gnafId: null,
  isIPND: false,
  isPrimary: false,
  isVerified: false,
  levelNumber: null,
  levelType: null,
  localityName: null,
  lotIdentifier: null,
  organizationName: null,
  postcode: null,
  stateTerritory: null,
  street: null,
  streetName: null,
  streetNumber1: null,
  streetNumber2: null,
  streetSuffix: null,
  streetType: null,
  type: null,
  unitIdentifier: null,
  unitType: null,
};

@Component({
  selector: 'kt-modal-address-add',
  templateUrl: './modal-address-add.component.html',
  styleUrls: ['./modal-address-add.component.scss'],
})
export class ModalAddressAddComponent implements OnInit {
  @Input() public mode;
  @Input() public customerId;
  @Input() public prospectId;
  @Input() public partnerId;
  @Input() public row;
  @Input() type: 'customer' | 'partner' | 'prospect' = null;
  @Input() ownerId: number = null;

  errorMessage: string;

  searching = false;
  searchFailed = false;

  public model: any;
  public address: any = {};

  addressModalForm: FormGroup;
  titleFunction = '';
  manualAddressChecked = false;
  manualAddress = false;
  addressType: string = '';
  primary = false;
  addressEdit:any={};
  currentFullAddress: String = '';
  address1: string = '';
  address2: string = '';
  city: string = '';
  state: string = '';
  postCode: string = '';
  hideSearchingWhenUnsubscribed = new Observable(() => () => (this.searching = false));
  address$ = this.addressStore.address$;



  constructor(public activeModal: NgbActiveModal, 
    private logixapi: LogixapiService,
    private addressStore: AddressService) {}

  ngOnInit(): void {

    // this.route.parent.paramMap.subscribe(async (params) => {
    //   console.log(params);
    //   this.accountId = params.get('accountId');
    // });


    
    this.addressModalForm = new FormGroup({
      type: new FormControl(this.address.type, Validators.required),
      addressLine1: new FormControl(this.address.addressLine1, Validators.required),
      addressLine2: new FormControl(this.address.addressLine2),
      postcode: new FormControl(this.address.postcode, Validators.required),
      localityName: new FormControl(this.address.localityName, Validators.required),
      stateTerritory: new FormControl(this.address.stateTerritory, Validators.required),
      addressFinderId: new FormControl(this.address.addressFinderId),
      boxIdentifier: new FormControl(this.address.boxIdentifier),
      boxType: new FormControl(this.address.boxType),
      canonicalAddressId: new FormControl(this.address.canonicalAddressId),
      countryCode: new FormControl(this.address.countryCode),
      description: new FormControl(this.address.description),
      fullAddress: new FormControl(this.address.fullAddress),
      gnafId: new FormControl(this.address.gnafId),
      levelNumber: new FormControl(this.address.levelNumber),
      levelType: new FormControl(this.address.levelType),
      lotIdentifier: new FormControl(this.address.lotIdentifier),
      organizationName: new FormControl(this.address.organizationName),
      street: new FormControl(this.address.street),
      streetName: new FormControl(this.address.streetName),
      streetNumber1: new FormControl(this.address.streetNumber1),
      streetNumber2: new FormControl(this.address.streetNumber2),
      streetSuffix: new FormControl(this.address.streetSuffix),
      streetType: new FormControl(this.address.streetType),
      unitIdentifier: new FormControl(this.address.unitIdentifier),
      unitType: new FormControl(this.address.unitType),
      currentFullAddress: new FormControl(this.fnGetRowFullAddress()),
    });

    if(this.mode == 'edit'){
      this.addressEdit.currentFullAddress = this.row.fullAddress;
    }
  }

  fnGetRowFullAddress(){
    if(this.mode === 'edit'){
      return this.row.fullAddress;
    }else {
      return '';
    }
  }

  togolmanualAddress(e) {
    this.manualAddressChecked = e.target.checked;
  }

  async submit() {
    console.log('submit button');
  }

  async onSubmit() {
     if (this.address) {
        let apiUrl = this.buildApiUrl();
        let result;

      try {

        if (this.mode === 'add') {

          result = await this.logixapi.post(apiUrl, this.addressModalForm.value).toPromise();
          await this.loadContactsList();

        } else if (this.mode === 'edit') {

          let apiUrlEdit = `${apiUrl}/${this.row.id}`;

          result = await this.logixapi.put(apiUrlEdit, this.addressModalForm.value).toPromise();
          await this.loadContactsList();

        }

      } catch (err) {
        if (err.error && err.error.error) this.setError(err.error.error.message);
      }

      if (result) {
        this.activeModal.close(true);
        
      }
    }
  }

  setError(message: string) {
    this.errorMessage = message;

    setTimeout(() => {
      this.errorMessage = null;
    }, 5000);
  }

  buildApiUrl() {
    let apiUrl = '';

    if (this.customerId) {
      apiUrl = `/Customers/${this.customerId}/Addresses`;
    } else if (this.partnerId) {
      apiUrl = `/Partners/${this.partnerId}/Addresses`;
    } else if (this.prospectId) {
      apiUrl = `/Prospects/${this.prospectId}/Addresses`;
    }

    return apiUrl;
  }

  formatAddressSearch = (address: any) => {
    return address.full_address;
  };

  async selectedAddress(item: any) {
    if (item.item.id === 'notfound') {
      this.manualAddress = true;
    } else {
      this.manualAddress = false;
      let address = await this.logixapi.get(`Tools/AddressMetadata/${item.item.id}`, null).toPromise();

      if (address.success && address.success === true) {
        this.address = humps.camelizeKeys(address);

        console.log('this address' + JSON.stringify(this.address));
        for (let key of Object.keys(this.address)) {
          if (!['id'].includes(key)) {
            // this.addressModalForm.value[key] = this.address[key];
            let obj = {};
            obj[key] = this.address[key];
            this.addressModalForm.patchValue(obj);
          }
        }

        this.addressModalForm.patchValue({ addressFinderId: this.address.id });
        this.addressModalForm.markAllAsTouched();
      }
    }
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.searching = true)),
      switchMap((term) => {
        return this.logixapi.post('Tools/AddressSearch', { address: term }).pipe(
          tap(() => (this.searchFailed = false)),
          map((response) => {
            response.completions.push({ id: 'notfound', full_address: 'Address not listed' });

            return response.completions;
          }),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })
        );

        /*
        this.logixapi.post('Tools/AddressSearch', { address: term }).pipe((res) => {
          console.log(res);
          return of(['a', 'b']);
        });

        // this.searchFailed = true;
        return of(['b', 'a']);*/
      }),
      tap(() => (this.searching = false))
    );



    /**
   * Load address List from logixapi
   */
  async loadContactsList() {
    let url = '';
    url = this.buildApiUrl();

    let contacts = await this.logixapi.get(url, null).toPromise();

    this.addressStore.address = contacts;
    
    return Promise.all(
      contacts.map(async (contact) => {
        return contact;
      })
    );
  }

}
