import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timeAgo',
})
export class TimeAgoPipe implements PipeTransform {
  transform(datetime: any): any {
    console.log(datetime);

    if (moment(datetime, moment.ISO_8601, true).isValid()) {
      return moment(datetime, moment.ISO_8601, true).fromNow();
    }
    return moment(datetime + '', 'DD/MM/YYYY HH:mm:ss').fromNow();
  }
}
