import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable, of } from 'rxjs';
import { LogixapiService } from './logixapi.service';
import { ReminderPopupComponent } from '../views/pages/shared/reminder-popup/reminder-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class ReminderService {
  private readonly _reminders = new BehaviorSubject<Reminders>(null);
  private reminderQueue = [];

  private activeReminder = false;

  readonly reminders$ = this._reminders.asObservable();

  public get reminders(): Reminders {
    return this._reminders.getValue();
  }

  public set reminders(val: Reminders) {
    this._reminders.next(val);
  }

  constructor(private logixapi: LogixapiService, public modalService: NgbModal) {
    logixapi.statusEvents.subscribe((data) => {
      if (data === 'connected') {
        this.subscribeReminderEvents();
      }
    });
  }

  subscribeReminderEvents() {
    this.logixapi.socket.on('reminder', (reminderEvent) => {
      this.addReminderToQueue(reminderEvent);
    });
  }

  addReminderToQueue(reminder) {
    this.reminderQueue.push(reminder);
    this.prcoessReminderQueue();
  }

  prcoessReminderQueue() {
    if (this.activeReminder || this.reminderQueue.length === 0) return;

    this.openReminderPopup(this.reminderQueue[0]);
  }

  reminderClosed(reminder) {
    console.log(reminder);
    this.reminderQueue = this.reminderQueue.filter((r) => r.id !== reminder.id);
    console.log(this.reminderQueue);
    this.activeReminder = false;
    this.prcoessReminderQueue();
  }

  openReminderPopup(reminder) {
    if (this.activeReminder) return;

    const modalRef = this.modalService.open(ReminderPopupComponent);
    this.activeReminder = true;
    modalRef.componentInstance.title = 'Reminder';
    //modalRef.componentInstance.src = link;
    modalRef.componentInstance.reminder = reminder;

    modalRef.result.then(
      () => {
        this.reminderClosed(reminder);
      },
      () => {
        this.reminderClosed(reminder);
      }
    );
  }
}

export interface Reminders {
  // id: number;
  // status: string;
  // OrderLines: {};
}
