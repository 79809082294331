import { animate, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalConfig, Toast, ToastPackage, ToastrService } from 'ngx-toastr';

@Component({
  selector: '[bootstrap-toast-component]',
  template: `
    <div
      class="toast {{
        options.notification.status ? 'toast-border-ticket-' + options.notification.status.toLowerCase() : ''
      }}"
      role="alert"
      style="opacity: 1; !important; background-color: white; width:320px; border-left-width: 4px; border-left-style: solid; font-size: 0.97rem;"
    >
      <div class="toast-header" style="border-bottom: none;">
        <strong class="me-auto">{{ title || 'default header' }}</strong>
        <div
          style="
    float: right;
    position: absolute;
    right: 10px;
"
        >
          <i class="flaticon2-delete icon-nm cursor-pointer" (click)="close()"></i>
        </div>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          *ngIf="options.closeButton"
          (click)="close()"
        ></button>
      </div>
      <div class="toast-body cursor-pointer">
        <div (click)="openTicket()" role="alert" aria-live="polite" [attr.aria-label]="message">{{ message }}</div>
      </div>
    </div>
  `,
  preserveWhitespaces: false,
  animations: [
    trigger('flyInOut', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)' }),
        animate('200ms ease-in', style({ transform: 'translateY(0%)' })),
      ]),
      transition(':leave', [animate('200ms ease-in', style({ transform: 'translateY(-100%)' }))]),
    ]),
  ],
})
export class CustomToast extends Toast {
  options: any;
  //options: {};
  //  containerClass = 'toast';
  // containerStyle = 'opacity: 1!important';
  timeOut: 0;
  extendedTimeOut: 0;

  constructor(protected toastrService: ToastrService, public toastPackage: ToastPackage, private router: Router) {
    super(toastrService, toastPackage);

    console.log(this.options);
  }

  handleClick(event: Event, id: string) {
    console.log('click action');
    event.stopPropagation();
    this.toastPackage.triggerAction(id);
    return false;
  }

  openTicket() {
    setTimeout(() => {
      this.close();
    }, 600);

    if (this.options.ticketId) {
      this.router.navigate([`/ticketing/ticket/${this.options.ticketId}`]);
    }
  }

  close() {
    this.toastPackage.triggerAction('close');
  }
}
/*
export interface CustomToastConfig extends GlobalConfig {
  context: CustomContextEnum;
  buttons: {
    style: string;
    text: string;
    id: string;
  }[];
}*/
