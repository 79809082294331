  <!-- Model shows Add Contact information -->
  <div>
    <form [formGroup]="contactModalForm">
      <div class="modal-header">
        <h4 class="modal-title">Contact - {{ titleFunction }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
      </div>
      <div class="modal-body">
        <div class="row">

          <div class="alert alert-custom alert-notice alert-light-danger fade show" role="alert" *ngIf="errorMessage">
            <div class="alert-icon"><i class="flaticon-warning"></i></div>
            <div class="alert-text">
              An error occurred
              <br/>
              {{errorMessage}}
            </div>
            <div class="alert-close">
              <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true"><i class="ki ki-close"></i></span>
              </button>
            </div>
          </div>
          <div class="col-xl-2"></div>
          <div class="col-xl-7 my-2">

            <div class="form-group row">
              <label class="col-form-label col-4 text-lg-right text-left">Title</label>
              <div class="col-8">
                <select class="form-control form-control-lg form-control-solid"
                id="personTitle"
                placeholder="Select"
                formControlName="personTitle">
                  <option value="">Select</option>
                  <option value="Mr">Mr</option>
                  <option value="Ms">Ms</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Miss">Miss</option>
                  <option value="Dr">Dr</option>
                  <option value="Prof">Prof</option>
                </select>
                <small *ngIf="contactModalForm.get('personTitle').invalid && contactModalForm.get('personTitle').touched" class="text-danger">Title is required</small>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-4 text-lg-right text-left">First name</label>
              <div class="col-8">
                <input type="text"
                class="form-control form-control-lg form-control-solid"
                placeholder="First name"
                formControlName="firstName"/>
                <small *ngIf="firstName.invalid && firstName.touched" class="text-danger">First name is required</small>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-form-label col-4 text-lg-right text-left">Last name</label>
              <div class="col-8">
                <input class="form-control form-control-lg form-control-solid"
                type="text"
                placeholder="Last name"
                formControlName="lastName">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-4 text-lg-right text-left">Position</label>
              <div class="col-8">
                <input class="form-control form-control-lg form-control-solid"
                type="text"
                placeholder="Position"
                formControlName="title">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-4 text-lg-right text-left">Phone</label>
              <div class="col-8">
                <input class="form-control form-control-lg form-control-solid"
                type="text"
                placeholder="Phone"
                formControlName="phone">
                <small *ngIf="phone.invalid && phone.touched"  class="text-danger">Phone number is required</small>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-4 text-lg-right text-left">Mobile</label>
              <div class="col-8">
                <input class="form-control form-control-lg form-control-solid"
                type="text"
                placeholder="Mobile"
                formControlName="mobile">
                <small *ngIf="mobile.invalid && mobile.touched" class="text-danger">Mobile number is required</small>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-4 text-lg-right text-left">Email</label>
              <div class="col-8">
                <input class="form-control form-control-lg form-control-solid"
                type="email"
                placeholder="Email"
                formControlName="email">
                <small *ngIf="email.invalid && email.touched"  class="text-danger">Email is required</small>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-4 text-lg-right text-left">Gender</label>
              <div class="col-8">
                <select class="form-control form-control-lg form-control-solid"
                id="gender" placeholder="Select"
                formControlName="gender">
                  <option value="">Select</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-4 text-lg-right text-left">DOB</label>
              <div class="col-8">
                  <input type="text"
                  class="form-control form-control-lg form-control-solid"
                  placeholder="DOB"
                  formControlName="dob">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-4 text-lg-right text-left">Location</label>
              <div class="col-8">
                <input type="text"
                class="form-control form-control-lg form-control-solid"
                placeholder="Location"
                formControlName="location">
              </div>
            </div>

            <div class="form-group row">
              <label class="col-form-label col-4 text-lg-right text-left">Roles</label>
              <div class="col-8">
                  <div class="form-group row">
                      <label class="col-3 col-form-label text-right">Billing</label>
                      <div class="col-3 text-right">
                          <span class="switch switch-success">
                              <label>
                              <input type="checkbox"
                              checked="checked"
                              formControlName="billingNotification"/>
                              <span></span>
                              </label>
                          </span>
                      </div>
                      <label class="col-3 col-form-label text-right">Account</label>
                      <div class="col-3 text-right">
                          <span class="switch switch-success">
                              <label>
                              <input type="checkbox"
                              checked="checked"
                              formControlName="accountNotification"/>
                              <span></span>
                              </label>
                          </span>
                      </div>
                      <label class="col-3 col-form-label text-right">Technical</label>
                      <div class="col-3 text-right">
                          <span class="switch switch-success">
                              <label>
                              <input type="checkbox"
                              checked="checked"
                              formControlName="technicalNotification"/>
                              <span></span>
                              </label>
                          </span>
                      </div>
                      <label class="col-3 col-form-label text-right">Promotions</label>
                      <div class="col-3 text-right">
                          <span class="switch switch-success">
                              <label>
                              <input type="checkbox"
                              checked="checked"
                              formControlName="salesNotification"/>
                              <span></span>
                              </label>
                          </span>
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button (click)="onSubmit()" [disabled]="contactModalForm.invalid" type="button" class="btn btn-primary btn-sm">Save</button>
        <button type="button" class="btn btn-secondary btn-sm" (click)="activeModal.close('Close click')">Close</button>
      </div>
    </form>
  </div>
