import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  private readonly _address = new BehaviorSubject<Address>(null);
  readonly address$ = this._address.asObservable();

  public get address(): Address {
    return this._address.getValue();
  }

  public set address(val: Address) {
    this._address.next(val);
  }

  constructor() { }
}

export interface Address {
  id: number;
  // status: string;
  // OrderLines: {};

}
