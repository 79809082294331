import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LogixapiService } from '../../../../services/logixapi.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { ReminderService } from '../../../../services/reminder.service';
import * as moment from 'moment';

@Component({
  selector: 'kt-reminder',
  templateUrl: './reminder.component.html',
  styleUrls: ['./reminder.component.scss'],
})
export class ReminderComponent implements OnInit {
  reminder$ = this.reminderStore.reminders$;
  @Input() public addressId;
  @Input() public customerId;
  @Input() public partnerId;
  @Input() public prospectId;
  @Input() public quoteId;
  @Input() public ticketId;
  @Input() public reminderData;
  @Input() public mode;

  title = 'Add';
  reminderForAgentOrDepartmen: String = 'Agent';
  reminder: any = {};
  agentList: any = [];
  timeStamp: any = {};
  reminderDate: Date;
  meridian = true;
  createdByUserId: null;
  userId: any = null;

  @Input() src;
  constructor(
    private route: ActivatedRoute,
    private logixapi: LogixapiService,
    private toastr: ToastrService,
    public activeModal: NgbActiveModal,
    private reminderStore: ReminderService
  ) {}

  ngOnInit() {
    this.fnGetAgents();
    this.userId = this.logixapi.getUser().userId;

    if (this.mode === 'Edit') {
      this.reminder = this.reminderData;
      let dateX = this.reminderData.startDatetime;

      this.timeStamp.date = {
        year: Number(moment(dateX).format('YYYY')),
        month: Number(moment(dateX).format('MM')),
        day: Number(moment(dateX).format('DD')),
      };
      this.timeStamp.time = {
        hour: Number(moment(dateX).format('HH')),
        minute: Number(moment(dateX).format('mm')),
      };
    }
  }

  async onSubmit(form) {
    let dateString = this.timeStamp.date.year + '-' + this.timeStamp.date.month + '-' + this.timeStamp.date.day;
    let timeString = this.timeStamp.time.hour + ':' + this.timeStamp.time.minute + ':00';
    let dateObj = new Date(dateString + ' ' + timeString);
    let startDatetime = moment(dateObj).format();

    this.reminder.startDatetime = startDatetime;
    this.reminder.timezone = moment(startDatetime).format('Z');
    this.reminder.title = ' ';

    this.reminder.createdByUserId = this.userId;

    if (this.mode === 'Add') {
      if (this.addressId) this.reminder.addressId = this.addressId;
      if (this.customerId) this.reminder.customerId = this.customerId;
      if (this.partnerId) this.reminder.partnerId = this.partnerId;
      if (this.prospectId) this.reminder.prospectId = this.prospectId;
      if (this.quoteId) this.reminder.quoteId = this.quoteId;
      if (this.ticketId) this.reminder.ticketId = this.ticketId;

      try {
        await this.logixapi.post(`/Reminders/`, this.reminder).toPromise();
        await this.fnGetRemiderList();

        this.showToasterSuccess('Reminder added successfully', 'Reminder added successfull');
        this.activeModal.close(true);
      } catch (err) {
        this.showToasterError('Someting wrong', 'Someting wrong');
      }
    } else if (this.mode === 'Edit') {
      this.reminder.title = ' ';
      // console.log('reminder-edit', this.reminder);

      if (this.reminder.completed === true) {
        this.reminder.completedByUserId = this.userId;
        this.reminder.completedDatetime = moment().format();
      }

      try {
        await this.logixapi.put(`/Reminders/${this.reminder.id}`, this.reminder).toPromise();
        await this.fnGetRemiderList();

        this.showToasterSuccess('Reminder updated successfully', 'Reminder updated successfully');
        this.activeModal.close(true);
      } catch (err) {
        this.showToasterError('Someting wrong', '');
      }
    }
  }

  async fnGetAgents() {
    try {
      this.agentList = await this.logixapi.get(`/Agents/`, { where: { deleted: false } }).toPromise();
    } catch (err) {
      console.log(err);
    }
  }

  async fnGetRemiderList() {
    let whereCondition = {
      ownerUserId: this.userId,
      completed: false,
    };

    let remiders = await this.logixapi.get(`/Reminders/`, { where: whereCondition }).toPromise();

    this.reminderStore.reminders = remiders;

    console.log('this.reminderStore.reminder', this.reminderStore.reminders);

    return (this.reminderStore.reminders = remiders);
  }

  showToasterSuccess(message, title) {
    this.toastr.success(message, title, { timeOut: 1000 });
  }

  showToasterError(message, title) {
    this.toastr.error(message, title, { timeOut: 1000 });
  }
}
