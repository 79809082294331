<ng-container *ngIf="layout === 'subheader-v1'">
    <kt-subheader1
      [fixed]="fixed"
      [clear]="clear"
      [style]="style"
      [displayDesc]="displayDesc"
      [subheaderClasses]="subheaderClasses"
      [subheaderContainerClasses]="subheaderContainerClasses"
      [displayDaterangepicker]="displayDaterangepicker"
    ></kt-subheader1>
</ng-container>

<ng-container *ngIf="layout === 'subheader-v2'">
    <kt-subheader2
      [fixed]="fixed"
      [clear]="clear"
      [style]="style"
      [displayDesc]="displayDesc"
      [subheaderClasses]="subheaderClasses"
      [subheaderContainerClasses]="subheaderContainerClasses"
      [displayDaterangepicker]="displayDaterangepicker"
    ></kt-subheader2>
</ng-container>

<ng-container *ngIf="layout === 'subheader-v3'">
    <kt-subheader3
      [fixed]="fixed"
      [clear]="clear"
      [style]="style"
      [displayDesc]="displayDesc"
      [subheaderClasses]="subheaderClasses"
      [subheaderContainerClasses]="subheaderContainerClasses"
      [displayDaterangepicker]="displayDaterangepicker"
    ></kt-subheader3>
</ng-container>
