<div class="modal-header">
    <h4 class="modal-title">{{ reminder.type }} <i class="fas fa-user-clock text-primary font-s-20"></i></h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
<form (ngSubmit)="onSubmit(reminderFrom)" #reminderFrom = "ngForm">
    <div class="modal-body">
        <div class="form-group row">
            <strong class=" col-3 text-lg-right text-left">Start Time</strong>
            <div class="col-9">
                {{ reminder.startDatetime | timeAgo }}
            </div>
        </div>
        <div class="form-group row">
            <strong class=" col-3 text-lg-right text-left">Description</strong>
            <div class="col-9">
                {{ reminder.description }}
            </div>
        </div>
        <div class="form-group row" *ngIf="reminder.ticketId">
            <strong class=" col-3 text-lg-right text-left">Ticket</strong>
            <div class="col-9">
                <a (click)='openTicket()' class="text-primary cursor-pointer">{{ reminder.ticketId }} <i class="text-primary fas fa-external-link-square-alt"></i></a>
            </div>
        </div>

        <div class="form-group row" *ngIf="reminder.prospectId">
            <strong class=" col-3 text-lg-right text-left">Prospect</strong>
            <div class="col-9">
                <a (click)='openProspect()' class="text-primary cursor-pointer">{{ reminder.prospectId }} <i class="text-primary fas fa-external-link-square-alt"></i></a>
            </div>
        </div>

        <div class="form-group row" *ngIf="reminder.customerId">
            <strong class=" col-3 text-lg-right text-left">Customer</strong>
            <div class="col-9">
                <a (click)='openCustomer()' class="text-primary cursor-pointer">{{ reminder.CustomerId }} <i class="text-primary fas fa-external-link-square-alt"></i></a>
            </div>
        </div>

        <div class="form-group">
            <label>To be reminded in</label>
            <select class="form-control form-control-lg form-control-solid" 
                placeholder="Select"
                name="postponeBy"
                [(ngModel)] = 'postponeBy'
                #typeInput="ngModel">
                <option value="">Select</option>
                <option value="5 minutes">5 minutes</option>
                <option value="10 minutes">10 minutes</option>
                <option value="15 minutes">15 minutes</option>
                <option value="20 minutes">20 minutes</option>
                <option value="25 minutes">25 minutes</option>
                <option value="30 minutes">30 minutes</option>
                <option value="1 hour">1 hour</option>
                <option value="2 hours">2 hours</option>
                <option value="4 hours">4 hours</option>
                <option value="8 hours">8 hours</option>
                <option value="1 day">1 day</option>
                <option value="2 days">2 days</option>
                <option value="3 days">3 days</option>
                <option value="4 days">4 days</option>
                <option value="1 week">1 week</option>
                <option value="2 week">2 week</option>
            </select>
        </div>  

        <div class="form-group">
            <label>Or</label>
        </div>


        <div class="form-group">
            <div class="checkbox-inline">
                <label class="checkbox">
                    <input type="checkbox" 
                    name="Checkboxes2"
                    checked
                    [(ngModel)]="reminder.completed"/>
                    <span></span>
                    Task completed
                </label>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-sm" (click)="activeModal.close('Close click')">Close</button>
        <button type="submit" 
        class="btn btn-primary btn-sm"
        [disabled] ="reminderFrom.invalid">Save</button>
    </div>
</form>

