import { EventEmitter, Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Socket } from 'ngx-socket-io';

// RxJS
import { Observable, Subject, of, throwError } from 'rxjs';
import { DataTableItemModel } from '../core/_base/layout';
import { User } from '../core/auth';
import { catchError, map, finalize, takeUntil, tap } from 'rxjs/operators';

// const API_DEV_URL = 'http://logix.dev.movox.com.au';
//const API_DEV_URL = 'http://192.168.2.150:3000';
const API_DEV_URL = 'https://5g.movox.com.au';
const API_STAGING_URL = 'https://5g.movox.com.au';

@Injectable({
  providedIn: 'root',
})
export class LogixapiService implements OnInit {
  public statusEvents = new EventEmitter();
  public socketConnected = false;
  private eventHandlers = {};

  headers = new HttpHeaders().set('Content-Type', 'application/json');
  apiBaseUrl: string = API_DEV_URL;
  user: object;
  loggedInServerCheck: boolean;
  isLoggedIn$: Observable<boolean>;
  public currentUser: any;
  public socket;

  /**
   * Service Constructor
   *
   * @param http: HttpClient
   */
  constructor(private http: HttpClient, public router: Router) {
    this.currentUser = null;
    this.apiBaseUrl = API_DEV_URL;

    if (window.location.host === '5g.movox.com.au') {
      this.apiBaseUrl = API_STAGING_URL;
      // this.url = "http://192.168.2.38:3000";
    }

    this.setupSocketConnection();
  }

  ngOnInit() {}

  getToken() {
    return localStorage.getItem('access_token');
  }

  isLoggedIn(): boolean {
    const authToken = localStorage.getItem('access_token');
    return authToken !== null ? true : false;
  }

  setToken(token) {
    localStorage.setItem('access_token', token);
  }

  setupSocketConnection() {
    if (this.isLoggedIn()) {
      const user = this.getUser();

      if (user) {
        this.socket = new Socket({ url: this.apiBaseUrl, options: {} });

        this.socket.on('connect', () => {
          this.socket.emit('authentication', { id: this.getToken(), userId: user.userId });
          this.socket.on('authenticated', () => {
            console.log('Websocket connected');
            this.statusEvents.emit('connected');
          });

          this.socket.on('event', (logixEvent) => {
            const topic = `event.${logixEvent.event}`;
            console.log(logixEvent);
            this.sendEvent(topic, logixEvent);
            /*
            if (!this.checkRecent(notification.id)) {
              this.ea.publish(`notification.${notification.section}`, notification);
              this.addNotification(notification.id)
            }
*/
          });

          this.socket.on('notification', (notification) => {
            const topic = `notification.${notification.section}`;
            console.log(notification);
            this.sendEvent(topic, notification);
            /*
            if (!this.checkRecent(notification.id)) {
              this.ea.publish(`notification.${notification.section}`, notification);
              this.addNotification(notification.id)
            }
*/
          });
        });
      }
    }
  }

  sendEvent(topic: string, data: any) {
    console.log(data);
    if (this.eventHandlers[topic] && this.eventHandlers[topic].length > 0) {
      this.eventHandlers[topic].forEach((handler) => {
        handler(data);
      });
    }
  }

  subscribeTo(topic: string, handler: Function) {
    if (!this.eventHandlers[topic]) this.eventHandlers[topic] = [];

    this.eventHandlers[topic].push(handler);
  }

  setUser(user) {
    localStorage.setItem('u', JSON.stringify(user));
  }

  getUser() {
    let user = localStorage.getItem('u');

    if (user === null) return null;
    return JSON.parse(user);
  }

  getUserDetail(): Observable<object> {
    return this.get('User/getUser', null).pipe(
      tap((user) => {
        if (user) {
          return user;
        } else {
          return false;
        }
      }),
      catchError((err: any) => {
        this.logout();
        return of(null);
      })
    );
  }

  async getUserInfo() {
    const userTokenObj = this.getUser();

    if (userTokenObj === null) return null;

    this.currentUser = await this.get(`User/getUser`, null).toPromise();

    if (this.currentUser && this.currentUser.userId) return this.currentUser;

    return null;
  }

  getUserId() {
    return this.getUser().id;
  }

  login(email: string, pass: string): Observable<any> {
    return this.post('/User/login?[include=User]', { username: email, password: pass }).pipe(
      tap((user) => {
        if (user) {
          this.setToken(user.id);
          this.setUser(user);

          this.setupSocketConnection();

          return user;
          // this.router.navigateByUrl(this.returnUrl); // Main page
        } else {
          return false;
          // this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
        }
      })
    );
  }

  logout() {
    const removeToken = localStorage.removeItem('access_token');
    if (removeToken == null) {
      this.currentUser = null;
      this.router.navigate(['auth']);
    }
  }

  getFullAPIUrl(api: string): string {
    api = api[0] === '/' ? api : `/${api}`;
    api = `/api${api}?`;
    // console.log(this.apiBaseUrl)
    return `${this.apiBaseUrl}${api}`;
  }

  public post(api: string, body?: object) {
    let apiUrl = this.getFullAPIUrl(api);
    apiUrl = `${apiUrl}access_token=${this.getToken() || ''}`;

    let bodyString = null;
    if (body) {
      bodyString = JSON.stringify(body);
    }

    return this.http.post(apiUrl, bodyString, { headers: this.headers }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((err) => {
        return null;
      })
    );
  }

  public patch(api: string, body?: object) {
    let apiUrl = this.getFullAPIUrl(api);
    apiUrl = `${apiUrl}access_token=${this.getToken() || ''}`;

    let bodyString = null;
    if (body) {
      bodyString = JSON.stringify(body);
    }

    return this.http.patch(apiUrl, bodyString, { headers: this.headers }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  public put(api: string, body?: object) {
    let apiUrl = this.getFullAPIUrl(api);
    apiUrl = `${apiUrl}access_token=${this.getToken() || ''}`;

    let bodyString = null;
    if (body) {
      bodyString = JSON.stringify(body);
    }

    return this.http.put(apiUrl, bodyString, { headers: this.headers }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  public delete(api, filter) {
    let apiUrl = this.getFullAPIUrl(api);

    if (filter) {
      filter = JSON.stringify(filter);
      apiUrl = `${apiUrl}filter=${filter}&access_token=${this.getToken() || ''}`;
    } else {
      apiUrl = `${apiUrl}access_token=${this.getToken() || ''}`;
    }

    return this.http.delete(apiUrl, { headers: this.headers }).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  /**
   * Returns data from fake server
   */
  public get(api, filter) {
    let apiUrl = this.getFullAPIUrl(api);

    if (filter) {
      filter = JSON.stringify(filter);
      apiUrl = `${apiUrl}filter=${filter}&access_token=${this.getToken() || ''}`;
    } else {
      apiUrl = `${apiUrl}access_token=${this.getToken() || ''}`;
    }

    return this.http.get(apiUrl, { headers: this.headers }).pipe(
      map((res: any) => {
        return res;
      })
    );
  }
}
