<!-- begin:: Header Topbar -->
<ng-container>
  <ng-container *ngIf="searchDisplay">
    <!--begin: Search -->
    <kt-search-dropdown  [icon]="'./assets/media/svg/icons/General/Search.svg'" [useSVG]="true"></kt-search-dropdown>
    <!--end: Search -->
  </ng-container>

  <ng-container>
    <kt-topbar-chat [bgImage]="'./assets/media/misc/bg-1.jpg'" [pulse]="true" [skin]="'dark'" [icon]="'./assets/media/svg/icons/Code/Compiling.svg'" [useSVG]="true"></kt-topbar-chat>
  </ng-container>

  <ng-container *ngIf="notificationsDisplay">
    <!--begin: Notifications -->
      <kt-notification [bgImage]="'./assets/media/misc/bg-1.jpg'" [pulse]="true" [skin]="'dark'" [icon]="'./assets/media/svg/icons/Code/Compiling.svg'" [useSVG]="true"></kt-notification>
    <!--end: Notifications -->
  </ng-container>

  <ng-container *ngIf="quickActionsDisplay">
	  <!--begin: Quick actions -->
	  <kt-quick-action [bgImage]="'./assets/media/misc/bg-2.jpg'" [skin]="'dark'" [icon]="'./assets/media/svg/icons/Media/Equalizer.svg'" [useSVG]="true"></kt-quick-action>
	  <!--end: Quick actions -->
  </ng-container>



  <ng-container *ngIf="quickPanelDisplay">
    <!--begin: Quick panel toggle -->
    <div class="topbar-item" ngbTooltip="Quick panel">
      <div class="btn btn-icon btn-clean btn-lg mr-1" id="kt_quick_panel_toggle">
          <span class="svg-icon svg-icon-xl svg-icon-primary" [inlineSVG]="'./assets/media/svg/icons/Layout/Layout-4-blocks.svg'"></span>
      </div>
    </div>
    <!--end: Quick panel toggle -->
  </ng-container>


  <ng-container *ngIf="userDisplay">

    <ng-container >

      <kt-user-profile [userDropdownStyle]="userDropdownStyle"></kt-user-profile>
    </ng-container>
    <!--
    <ng-container *ngIf="userLayout === 'dropdown'">

      <kt-user-profile [userDropdownStyle]="userDropdownStyle"></kt-user-profile>
    </ng-container>
    <ng-container *ngIf="userLayout === 'offcanvas'">

      <kt-user-profile4></kt-user-profile4>

    </ng-container>

    -->
  </ng-container>
</ng-container>
<!-- end:: Header Topbar -->
