import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  private readonly _customer = new BehaviorSubject<Customer>(null);
  readonly customer$ = this._customer.asObservable();

  private readonly _customerStats = new BehaviorSubject<CustomerStats>(null);
  readonly customerStats$ = this._customerStats.asObservable();

  constructor() {}

  public get customer(): Customer {
    return this._customer.getValue();
  }

  public set customer(val: Customer) {
    this._customer.next(val);
  }

  public get customerStats(): CustomerStats {
    return this._customerStats.getValue();
  }

  public set customerStats(val: CustomerStats) {
    this._customerStats.next(val);
  }
}

export interface Customer {
  id: number;
  name: string;
  tradingName: string;
  [key: string]: any;
}

export interface CustomerStats {
  [key: string]: any;
}
