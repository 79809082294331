<!--begin::Notifications-->
<div ngbDropdown placement="bottom-right"  autoClose="outside" class="dropdown dropdown-fluid">
  <!--begin::Toggle-->
  <div ngbDropdownToggle class="topbar-item">
    <ng-container>
    <span class="svg-icon svg-icon-xl svg-icon-primary">
											<!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Group-chat.svg-->
											<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
												<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
													<rect x="0" y="0" width="24" height="24" />
													<path d="M16,15.6315789 L16,12 C16,10.3431458 14.6568542,9 13,9 L6.16183229,9 L6.16183229,5.52631579 C6.16183229,4.13107011 7.29290239,3 8.68814808,3 L20.4776218,3 C21.8728674,3 23.0039375,4.13107011 23.0039375,5.52631579 L23.0039375,13.1052632 L23.0206157,17.786793 C23.0215995,18.0629336 22.7985408,18.2875874 22.5224001,18.2885711 C22.3891754,18.2890457 22.2612702,18.2363324 22.1670655,18.1421277 L19.6565168,15.6315789 L16,15.6315789 Z" fill="#000000" />
													<path d="M1.98505595,18 L1.98505595,13 C1.98505595,11.8954305 2.88048645,11 3.98505595,11 L11.9850559,11 C13.0896254,11 13.9850559,11.8954305 13.9850559,13 L13.9850559,18 C13.9850559,19.1045695 13.0896254,20 11.9850559,20 L4.10078614,20 L2.85693427,21.1905292 C2.65744295,21.3814685 2.34093638,21.3745358 2.14999706,21.1750444 C2.06092565,21.0819836 2.01120804,20.958136 2.01120804,20.8293182 L2.01120804,18.32426 C1.99400175,18.2187196 1.98505595,18.1104045 1.98505595,18 Z M6.5,14 C6.22385763,14 6,14.2238576 6,14.5 C6,14.7761424 6.22385763,15 6.5,15 L11.5,15 C11.7761424,15 12,14.7761424 12,14.5 C12,14.2238576 11.7761424,14 11.5,14 L6.5,14 Z M9.5,16 C9.22385763,16 9,16.2238576 9,16.5 C9,16.7761424 9.22385763,17 9.5,17 L11.5,17 C11.7761424,17 12,16.7761424 12,16.5 C12,16.2238576 11.7761424,16 11.5,16 L9.5,16 Z" fill="#000000" opacity="0.3" />
												</g>
											</svg>
      <!--end::Svg Icon-->
										</span>
    </ng-container>

	</div>
  <!--end::Toggle-->

  <!--begin::Dropdown-->
  <div ngbDropdownMenu
		class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg show">
		<form>
			<!--begin::Header-->
			<div class="d-flex flex-column pt-10 bp-10 bgi-size-cover bgi-no-repeat rounded-top"
				[ngStyle]="{'background-image': backGroundStyle() }">
				<!--begin::Title-->
				<h4 class="d-flex flex-center rounded-top">
					<span class="text-white">User Notifications</span>
					<span class="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2">23 new</span>
				</h4>
				<!--end::Title-->
				<!--begin::Tabs-->
				<ul ktTabClickEvent
					class="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-2 px-6"
					role="tablist">
					<li class="nav-item">
						<a (click)="tab.select('tab-id-1')" class="nav-link active show" data-toggle="tab"
							href="javascript:;" role="tab" aria-selected="true">Alerts</a>
					</li>
					<li class="nav-item">
						<a (click)="tab.select('tab-id-2')" class="nav-link" data-toggle="tab" href="javascript:;"
							role="tab" aria-selected="false">Events</a>
					</li>
					<li class="nav-item">
						<a (click)="tab.select('tab-id-3')" class="nav-link" data-toggle="tab" href="javascript:;"
							role="tab" aria-selected="false">Logs</a>
					</li>
				</ul>
				<!--end::Tabs-->
			</div>
			<!--end::Header-->

			<ngb-tabset #tab="ngbTabset" class="tab-content">
				<ngb-tab id="tab-id-1" class="tab-pane">
					<ng-template ngbTabContent>
						<div class="p-8">
							<div class="pr-7 mr-n7 scroll" [perfectScrollbar]="{wheelPropagation: false}"
								[ngStyle]="{'max-height': '40vh', 'position': 'relative'}">
								<!--begin::Nav-->
								<div class="scroll" data-scroll="true" data-height="300" data-mobile-height="200">
									<div class="d-flex align-items-center mb-6">
										<div class="symbol symbol-40 symbol-light-primary mr-5">
											<span class="symbol-label">
												<span [inlineSVG]="'./assets/media/svg/icons/Home/Library.svg'"
													class="svg-icon svg-icon-lg svg-icon-primary"></span>
											</span>
										</div>
										<div class="d-flex flex-column font-weight-bold">
											<a href="#" class="text-dark text-hover-primary mb-1 font-size-lg">
												Briviba SaaS
											</a>
											<span class="text-muted">
												PHP, SQLite, Artisan CLIмм
											</span>
										</div>
									</div>
									<div class="d-flex align-items-center mb-6">
										<div class="symbol symbol-40 symbol-light-warning mr-5">
											<span class="symbol-label">
												<span [inlineSVG]="'./assets/media/svg/icons/Communication/Write.svg'"
													class="svg-icon svg-icon-lg svg-icon-warning"></span>
											</span>
										</div>
										<div class="d-flex flex-column font-weight-bold">
											<a href="#" class="text-dark-75 text-hover-primary mb-1 font-size-lg">
												Briviba SaaS
											</a>
											<span class="text-muted">
												PHP, SQLite, Artisan CLIмм
											</span>
										</div>
									</div>

									<div class="d-flex align-items-center mb-6">
										<div class="symbol symbol-40 symbol-light-success mr-5">
											<span class="symbol-label">
												<span [inlineSVG]="'./assets/media/svg/icons/Communication/Group-chat.svg'"
													class="svg-icon svg-icon-lg svg-icon-success"></span>
											</span>
										</div>
										<div class="d-flex flex-column font-weight-bold">
											<a href="#" class="text-dark text-hover-primary mb-1 font-size-lg">
												Briviba SaaS
											</a>
											<span class="text-muted">
												PHP, SQLite, Artisan CLIмм
											</span>
										</div>
									</div>
									<div class="d-flex align-items-center mb-6">
										<div class="symbol symbol-40 symbol-light-danger mr-5">
											<span class="symbol-label">
												<span [inlineSVG]="'./assets/media/svg/icons/General/Attachment2.svg'"
													class="svg-icon svg-icon-lg svg-icon-danger"></span>
											</span>
										</div>
										<div class="d-flex flex-column font-weight-bold">
											<a href="#" class="text-dark text-hover-primary mb-1 font-size-lg">
												Briviba SaaS
											</a>
											<span class="text-muted">
												PHP, SQLite, Artisan CLIмм
											</span>
										</div>
									</div>
									<div class="d-flex align-items-center mb-2">
										<div class="symbol symbol-40 symbol-light-info mr-5">
											<span class="symbol-label">
												<span [inlineSVG]="'./assets/media/svg/icons/General/Attachment2.svg'"
													class="svg-icon svg-icon-lg svg-icon-info"></span>
											</span>
										</div>
										<div class="d-flex flex-column font-weight-bold">
											<a href="#" class="text-dark text-hover-primary mb-1 font-size-lg">
												Briviba SaaS
											</a>
											<span class="text-muted">
												PHP, SQLite, Artisan CLIмм
											</span>
										</div>
									</div>
									<div class="d-flex align-items-center mb-2">
										<div class="symbol symbol-40 symbol-light-info mr-5">
											<span class="symbol-label">
												<span [inlineSVG]="'./assets/media/svg/icons/Communication/Mail-notification.svg'"
													class="svg-icon svg-icon-lg svg-icon-info"></span>
											</span>
										</div>
										<div class="d-flex flex-column font-weight-bold">
											<a href="#" class="text-dark text-hover-primary mb-1 font-size-lg">
												Briviba SaaS
											</a>
											<span class="text-muted">
												PHP, SQLite, Artisan CLIмм
											</span>
										</div>
									</div>
									<div class="d-flex align-items-center mb-2">
										<div class="symbol symbol-40 symbol-light-info mr-5">
											<span class="symbol-label">
												<span [inlineSVG]="'./assets/media/svg/icons/Design/Bucket.svg'" class="svg-icon svg-icon-lg svg-icon-info"></span>
											</span>
										</div>
										<div class="d-flex flex-column font-weight-bold">
											<a href="#" class="text-dark text-hover-primary mb-1 font-size-lg">
												Briviba SaaS
											</a>
											<span class="text-muted">
												PHP, SQLite, Artisan CLIмм
											</span>
										</div>
									</div>

								</div>
								<!--end::Nav-->
							</div>
						</div>
					</ng-template>
				</ngb-tab>
				<ngb-tab id="tab-id-2">
					<ng-template ngbTabContent>
						<div [perfectScrollbar]="{wheelPropagation: false}" class="scroll"
							[ngStyle]="{'max-height': '40vh', 'position': 'relative'}">
							<!--begin::Nav-->
							<div class="navi navi-hover scroll mb-3 mt-3 ps" data-scroll="true" data-height="300"
								data-mobile-height="200" style="height: 300px; overflow: hidden;">
								<a href="#" class="navi-item">
									<div class="navi-link">
										<div class="navi-icon mr-2">
											<i class="flaticon2-line-chart text-success"></i>
										</div>
										<div class="navi-text">
											<div class="font-weight-bold">
												New report has been received
											</div>
											<div class="text-muted">23 hrs ago</div>
										</div>
									</div>
								</a>

								<a href="#" class="navi-item">
									<div class="navi-link">
										<div class="navi-icon mr-2">
											<i class="flaticon2-paper-plane text-danger"></i>
										</div>
										<div class="navi-text">
											<div class="font-weight-bold">
												Finance report has been generated
											</div>
											<div class="text-muted">25 hrs ago</div>
										</div>
									</div>
								</a>

								<a href="#" class="navi-item">
									<div class="navi-link">
										<div class="navi-icon mr-2">
											<i class="flaticon2-user flaticon2-line- text-success"></i>
										</div>
										<div class="navi-text">
											<div class="font-weight-bold">
												New order has been received
											</div>
											<div class="text-muted">2 hrs ago</div>
										</div>
									</div>
								</a>

								<a href="#" class="navi-item">
									<div class="navi-link">
										<div class="navi-icon mr-2">
											<i class="flaticon2-pin text-primary"></i>
										</div>
										<div class="navi-text">
											<div class="font-weight-bold">
												New customer is registered
											</div>
											<div class="text-muted">3 hrs ago</div>
										</div>
									</div>
								</a>

								<a href="#" class="navi-item">
									<div class="navi-link">
										<div class="navi-icon mr-2">
											<i class="flaticon2-sms text-danger"></i>
										</div>
										<div class="navi-text">
											<div class="font-weight-bold">
												Application has been approved
											</div>
											<div class="text-muted">3 hrs ago</div>
										</div>
									</div>
								</a>

								<a href="#" class="navi-item">
									<div class="navi-link">
										<div class="navi-icon mr-2">
											<i class="flaticon2-pie-chart-3 text-warning"></i>
										</div>
										<div class="navinavinavi-text">
											<div class="font-weight-bold">
												New file has been uploaded
											</div>
											<div class="text-muted">5 hrs ago</div>
										</div>
									</div>
								</a>

								<a href="#" class="navi-item">
									<div class="navi-link">
										<div class="navi-icon mr-2">
											<i class="flaticon-pie-chart-1 text-info"></i>
										</div>
										<div class="navi-text">
											<div class="font-weight-bold">
												New user feedback received
											</div>
											<div class="text-muted">8 hrs ago</div>
										</div>
									</div>
								</a>

								<a href="#" class="navi-item">
									<div class="navi-link">
										<div class="navi-icon mr-2">
											<i class="flaticon2-settings text-success"></i>
										</div>
										<div class="navi-text">
											<div class="font-weight-bold">
												System reboot has been successfully completed
											</div>
											<div class="text-muted">12 hrs ago</div>
										</div>
									</div>
								</a>

								<a href="#" class="navi-item">
									<div class="navi-link">
										<div class="navi-icon mr-2">
											<i class="flaticon-safe-shield-protection text-primary"></i>
										</div>
										<div class="navi-text">
											<div class="font-weight-bold">
												New order has been placed
											</div>
											<div class="text-muted">15 hrs ago</div>
										</div>
									</div>
								</a>

								<a href="#" class="navi-item">
									<div class="navi-link">
										<div class="navi-icon mr-2">
											<i class="flaticon2-notification text-primary"></i>
										</div>
										<div class="navi-text">
											<div class="font-weight-bold">
												Company meeting canceled
											</div>
											<div class="text-muted">19 hrs ago</div>
										</div>
									</div>
								</a>

								<a href="#" class="navi-item">
									<div class="navi-link">
										<div class="navi-icon mr-2">
											<i class="flaticon2-fax text-success"></i>
										</div>
										<div class="navi-text">
											<div class="font-weight-bold">
												New report has been received
											</div>
											<div class="text-muted">23 hrs ago</div>
										</div>
									</div>
								</a>

								<a href="#" class="navi-item">
									<div class="navi-link">
										<div class="navi-icon mr-2">
											<i class="flaticon-download-1 text-danger"></i>
										</div>
										<div class="navi-text">
											<div class="font-weight-bold">
												Finance report has been generated
											</div>
											<div class="text-muted">25 hrs ago</div>
										</div>
									</div>
								</a>

								<a href="#" class="navi-item">
									<div class="navi-link">
										<div class="navi-icon mr-2">
											<i class="flaticon-security text-warning"></i>
										</div>
										<div class="navi-text">
											<div class="font-weight-bold">
												New customer comment recieved
											</div>
											<div class="text-muted">2 days ago</div>
										</div>
									</div>
								</a>

								<a href="#" class="navi-item">
									<div class="navi-link">
										<div class="navi-icon mr-2">
											<i class="flaticon2-analytics-1 text-success"></i>
										</div>
										<div class="navi-text">
											<div class="font-weight-bold">
												New customer is registered
											</div>
											<div class="text-muted">3 days ago</div>
										</div>
									</div>
								</a>
							</div>
							<!--end::Nav-->
						</div>
					</ng-template>
				</ngb-tab>
				<ngb-tab id="tab-id-3">
					<ng-template ngbTabContent>
						<div class="d-flex flex-center text-center text-muted" style="min-height: 200px;">
							All caught up!
							<br />No new notifications.
						</div>
					</ng-template>
				</ngb-tab>
			</ngb-tabset>
		</form>
	</div>
  <!--end::Dropdown-->
</div>
<!--end::Notifications-->
