import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, of, Observable } from 'rxjs';
import { LogixapiService } from './logixapi.service';

@Injectable({
  providedIn: 'root',
})
export class TicketService {

  private readonly _ticket = new BehaviorSubject<Ticket>(null);
  readonly ticket$ = this._ticket.asObservable();

  private readonly _ticketStats = new BehaviorSubject<TicketStats>(null);
  readonly ticketStats$ = this._ticketStats.asObservable();

  private readonly _ticketPriorityStatusStats = new BehaviorSubject<TicketStats>(null);
  readonly ticketPriorityStatusStats$ = this._ticketPriorityStatusStats.asObservable();

  private readonly _ticketDepartmentStats = new BehaviorSubject<TicketStats>(null);
  readonly ticketDepartmentStats$ = this._ticketDepartmentStats.asObservable();

  constructor(private logixapi: LogixapiService) {}

  public get ticket(): Ticket {
    return this._ticket.getValue();
  }

  public set ticket(val: Ticket) {
    this._ticket.next(val);
  }

  public get ticketStats(): TicketStats {
    return this._ticketStats.getValue();
  }

  public set ticketStats(val: TicketStats) {
    this._ticketStats.next(val);
  }

  public get ticketPriorityStatusStats(): TicketStats {
    return this._ticketPriorityStatusStats.getValue();
  }

  public set ticketPriorityStatusStats(val: TicketStats) {
    this._ticketPriorityStatusStats.next(val);
  }

  public get ticketDepartmentStats(): TicketStats {
    return this._ticketDepartmentStats.getValue();
  }

  public set ticketDepartmentStats(val: TicketStats) {
    this._ticketDepartmentStats.next(val);
  }

  async getPriorityStatusStats() {
    const priorityStatusStats = await this.logixapi.get(`Tickets/StatusPriorityStats`, null).toPromise();

    this.ticketPriorityStatusStats = priorityStatusStats;
  }

  async getDepartmentStats() {
    const ticketDepartmentStats = await this.logixapi.get(`Tickets/DepartmentStats`, null).toPromise();

    this.ticketDepartmentStats = ticketDepartmentStats;
  }

}

export interface Ticket {
  [key: string]: any;
}

export interface TicketStats {
  [key: string]: any;
}
