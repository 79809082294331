// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
// Components
import { BaseComponent } from './views/theme/base/base.component';

// Auth
import { AuthGuard } from './core/auth.guard';
import { CreateProspectComponent } from './views/pages/sales/create-prospect/create-prospect.component';

const routes: Routes = [
  { path: 'auth', loadChildren: () => import('./views/pages/auth/auth.module').then((m) => m.AuthModule) },
  { path: 'error', loadChildren: () => import('./views/pages/error/error.module').then((m) => m.ErrorModule) },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'prospect/create',
        component: CreateProspectComponent,
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./views/pages/dashboards/user-dashboard/user-dashboard.module').then((m) => m.UserDashboardModule),
      },
      {
        path: 'accounts',
        loadChildren: () =>
          import('./views/pages/accounts/account-management.module').then((m) => m.AccountManagementModule),
      },
      {
        path: 'finance',
        loadChildren: () => import('./views/pages/finance/finance.module').then((m) => m.FinanceModule),
      },
      {
        path: 'sales',
        loadChildren: () => import('./views/pages/sales/sales.module').then((m) => m.SalesModule),
      },
      {
        path: 'partners',
        loadChildren: () =>
          import('./views/pages/partners/partner-management.module').then((m) => m.PartnerManagementModule),
      },
      {
        path: 'ticketing',
        loadChildren: () => import('./views/pages/ticketing/ticketing.module').then((m) => m.TicketingModule),
      },
      {
        path: 'marketing',
        loadChildren: () => import('./views/pages/marketing/marketing.module').then((m) => m.MarketingModule),
      },

      {
        path: 'incidents',
        loadChildren: () => import('./views/pages/incidents/incidents.module').then((m) => m.IncidentsModule),
      },

      {
        path: 'prospects',
        loadChildren: () => import('./views/pages/prospects/prospects.module').then((m) => m.ProspectsModule),
      },

      {
        path: 'provisioning',
        loadChildren: () => import('./views/pages/provisioning/provisioning.module').then((m) => m.ProvisioningModule),
      },

      {
        path: 'settings',
        loadChildren: () => import('./views/pages/settings/settings.module').then((m) => m.SettingsModule),
      },

      /*Hiro addedd for Operation Dashboard*/
      {
        path: 'dashboard/operations',
        loadChildren: () =>
          import('./views/pages/dashboards/user-dashboard/user-dashboard.module').then((m) => m.UserDashboardModule),
      },

      /*Hiro addedd for Help desk Dashboard*/
      {
        path: 'dashboard/help-desk',
        loadChildren: () =>
          import('./views/pages/dashboards/help-desk-dashboard/help-desk-dashboard.module').then(
            (m) => m.HelpDeskDashboardModule
          ),
      },
      /*Hiro addedd for Finance Dashboard*/
      {
        path: 'dashboard/finance',
        loadChildren: () =>
          import('./views/pages/dashboards/finance-dashboard/finance-dashboard.module').then(
            (m) => m.FinanceDashboardModule
          ),
      },

      /*Hiro addedd for Sales Dashboard*/
      {
        path: 'dashboard/sales',
        loadChildren: () =>
          import('./views/pages/dashboards/sales-dashboard/sales-dashboard.module').then((m) => m.SalesDashboardModule),
      },

      /*Hiro addedd for ticket status Dashboard 20210616*/
      {
        path: 'dashboard/ticket-status',
        loadChildren: () =>
          import('./views/pages/dashboards/ticket-status-dashboard/ticket-status-dashboard.module').then(
            (m) => m.TicketStatusDashboardModule
          ),
      },

      /* {
        path: 'mail',
        loadChildren: () => import('./views/pages/apps/mail/mail.module').then(m => m.MailModule),
      },
      {
        path: 'ecommerce',
        loadChildren: () => import('./views/pages/apps/e-commerce/e-commerce.module').then(m => m.ECommerceModule),
      },
      {
        path: 'ngbootstrap',
        loadChildren: () => import('./views/pages/ngbootstrap/ngbootstrap.module').then(m => m.NgbootstrapModule),
      },
      {
        path: 'material',
        loadChildren: () => import('./views/pages/material/material.module').then(m => m.MaterialModule),
      },
      {
        path: 'user-management',
        loadChildren: () => import('./views/pages/user-management/user-management.module').then(m => m.UserManagementModule),
      },
      {
        path: 'wizard',
        loadChildren: () => import('./views/pages/wizard/wizard.module').then(m => m.WizardModule),
      },
      */
      {
        path: 'builder',
        loadChildren: () => import('./views/theme/content/builder/builder.module').then((m) => m.BuilderModule),
      },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
    ],
  },
  { path: '**', redirectTo: 'error/403', pathMatch: 'full' },
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
