// Angular
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgApexchartsModule } from 'ng-apexcharts';
// NgBootstrap
import { NgbDropdownModule, NgbTabsetModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
// Perfect Scrollbar
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
// Core module
import { CoreModule } from '../../core/core.module';
// CRUD Partials

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import {
  ActionNotificationComponent,
  AlertComponent,
  DeleteEntityDialogComponent,
  FetchEntityDialogComponent,
  UpdateStatusDialogComponent,
} from './content/crud';
// Layout partials
import {
  ContextMenu2Component,
  ContextMenuComponent,
  LanguageSelectorComponent,
  NotificationComponent,
  QuickActionComponent,
  QuickPanelComponent,
  QuickUserPanelComponent,
  ScrollTopComponent,
  SearchDefaultComponent,
  SearchDropdownComponent,
  SearchResultComponent,
  SplashScreenComponent,
  StickyToolbarComponent,
  Subheader1Component,
  Subheader2Component,
  Subheader3Component,
  SubheaderSearchComponent,
  UserProfile2Component,
  UserProfile3Component,
  UserProfileComponent,
  UserProfile4Component,
} from './layout';
import {
  Widget1SalesStatComponent,
  Widget9RecentActivitiesComponent,
  Widget12NewUsersComponent,
  Widget7WeeklySalesComponent,
  Widget1TasksOverviewComponent,
  Widget2NewArrivalsComponent,
  Widget3NewArrivalsAuthorsComponent,
  Widget4TodoComponent,
  Widget8TrendsComponent,
  Dropdown1Component,
  Dropdown2Component,
  Dropdown3Component,
  Dropdown4Component,
  Dropdown5Component,
  Dropdown6Component,
} from './content/dashboard-widgets';
// General
import { NoticeComponent } from './content/general/notice/notice.component';
import { PortletModule } from './content/general/portlet/portlet.module';
// Extra module
import { WidgetModule } from './content/widgets/widget.module';
// SVG inline
import { InlineSVGModule } from 'ng-inline-svg';
import { CartComponent } from './layout/topbar/cart/cart.component';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';

import { UserTicketsComponent } from '../pages/dashboards/user-dashboard/widgets/tickets/user-tickets.component';
import { OverviewComponent } from '../pages/dashboards/user-dashboard/widgets/overview/overview.component';
import { ReceivablesComponent } from '../pages/dashboards/user-dashboard/widgets/receivables/receivables.component';
import { TicketsComponent } from '../pages/dashboards/user-dashboard/widgets/tickets/tickets.component';
import { WeeklySalesStatusComponent } from '../pages/dashboards/user-dashboard/widgets/weekly-sales-status/weekly-sales-status.component';
import { RecentActivitesComponent } from '../pages/dashboards/user-dashboard/widgets/recent-activites/recent-activites.component';
import { SalesProgressComponent } from '../pages/dashboards/user-dashboard/widgets/sales-progress/sales-progress.component';

//Account widgets by Hiro - 2020-6-01
import { HwTicketsOpenWidgetComponent } from '../pages/accounts/account/widgets/hw-tickets-open-widget/hw-tickets-open-widget.component';
import { HwServicePlanWidgetComponent } from '../pages/accounts/account/widgets/hw-service-plan-widget/hw-service-plan-widget.component';
import { HwAccountBalanceWidgetComponent } from '../pages/accounts/account/widgets/hw-account-balance-widget/hw-account-balance-widget.component';
import { HwRecentActivitiesWidgetComponent } from '../pages/accounts/account/widgets/hw-recent-activities-widget/hw-recent-activities-widget.component';
import { HwBillingHistoryWidgetComponent } from '../pages/accounts/account/widgets/hw-billing-history-widget/hw-billing-history-widget.component';

//for Helpdesk Dashboard widgets by Hiro - 2020-06-02
import { HwHelpDeskWidgetComponent } from '../pages/dashboards/help-desk-dashboard/widgets/hw-help-desk-widget/hw-help-desk-widget.component';
import { HwHelpDeskRecentActivitiesWidgetComponent } from '../pages/dashboards/help-desk-dashboard/widgets/hw-help-desk-recent-activities-widget/hw-help-desk-recent-activities-widget.component';
//For Helpdesk Dashboard widgets by Hiro - 2020-06-03
import { HwHelpDeskTicketsListWidgetComponent } from '../pages/dashboards/help-desk-dashboard/widgets/hw-help-desk-tickets-list-widget/hw-help-desk-tickets-list-widget.component';
//For Finance Dashboard widget by Hiro - 2020-06-03
import { HwFinanceWidgetComponent } from '../pages/dashboards/finance-dashboard/widgets/hw-finance-widget/hw-finance-widget.component';
import { HwFinanceRecentActivityWidgetComponent } from '../pages/dashboards/finance-dashboard/widgets/hw-finance-recent-activity-widget/hw-finance-recent-activity-widget.component';
import { HwFinanceBillingHistoryWidgetComponent } from '../pages/dashboards/finance-dashboard/widgets/hw-finance-billing-history-widget/hw-finance-billing-history-widget.component';
//For Sales Dashboard widget by Hiro - 2020-06-09
import { HwSalesSalesHistoryWidgetComponent } from '../pages/dashboards/sales-dashboard/widgets/hw-sales-sales-history-widget/hw-sales-sales-history-widget.component';
import { HwSalesProspectsWidgetComponent } from '../pages/dashboards/sales-dashboard/widgets/hw-sales-prospects-widget/hw-sales-prospects-widget.component';
import { HwSalesQuotationsWidgetComponent } from '../pages/dashboards/sales-dashboard/widgets/hw-sales-quotations-widget/hw-sales-quotations-widget.component';
import { HwSalesRecentActivityWidgetComponent } from '../pages/dashboards/sales-dashboard/widgets/hw-sales-recent-activity-widget/hw-sales-recent-activity-widget.component';
import { HwSalesWidgetComponent } from '../pages/dashboards/sales-dashboard/widgets/hw-sales-widget/hw-sales-widget.component';

import { CustomersTicketsNewWidgetComponent } from '../pages/accounts/widgets/customers-tickets-new-widget/customers-tickets-new-widget.component';
import { HwAMNewCustomersWidgetComponent } from '../pages/accounts/widgets/hw-amnew-customers-widget/hw-amnew-customers-widget.component';
import { HwAMActivePlansWidgetComponent } from '../pages/accounts/widgets/hw-amactive-plans-widget/hw-amactive-plans-widget.component';
import { CustomersOpenQuotesWidgetComponent } from '../pages/accounts/widgets/customers-open-quotes-widget/customers-open-quotes-widget.component';
import { HotquotationWidgetComponent } from '../pages/shared/hotquotation-widget/hotquotation-widget.component';

import { PartnerTicketsOpenWidgetComponent } from '../pages/partners/widgets/partner-tickets-open-widget/partner-tickets-open-widget.component';
import { HwPMNewCustomersWidgetComponent } from '../pages/partners/widgets/hw-pmnew-customers-widget/hw-pmnew-customers-widget.component';
import { HwPMActivePlansWidgetComponent } from '../pages/partners/widgets/hw-pmactive-plans-widget/hw-pmactive-plans-widget.component';
import { HwPMPendingInvoicesWidgetComponent } from '../pages/partners/widgets/hw-pmpending-invoices-widget/hw-pmpending-invoices-widget.component';

//For Finance Billing History Chart by Hiro - 2020-06-16
import { HwFinanceBillingHistoryChartWidgetComponent } from '../pages/finance/widgets/hw-finance-billing-history-chart-widget/hw-finance-billing-history-chart-widget.component';
import { HwFinanceRevenueChartWidgetComponent } from '../pages/finance/widgets/hw-finance-revenue-chart-widget/hw-finance-revenue-chart-widget.component';

//For Sales Customer Growth/Qutations wons / Agent performance / Quotation pipeline by Hiro 2020-06-17
import { HwSalesCustomerGrowthChartWidgetComponent } from '../pages/sales/widgets/hw-sales-customer-growth-chart-widget/hw-sales-customer-growth-chart-widget.component';
import { HwSalesQuotationsWonChartWidgetComponent } from '../pages/sales/widgets/hw-sales-quotations-won-chart-widget/hw-sales-quotations-won-chart-widget.component';
import { HwSalesAgentPerformanceChartWidgetComponent } from '../pages/sales/widgets/hw-sales-agent-performance-chart-widget/hw-sales-agent-performance-chart-widget.component';
import { HwSalesQuotationsPipelineChartWidgetComponent } from '../pages/sales/widgets/hw-sales-quotations-pipeline-chart-widget/hw-sales-quotations-pipeline-chart-widget.component';

//Ticketing by ?Hiro 20200622
import { HwTicketingDropdown1WidgetsComponent } from '../pages/ticketing/widgets/hw-ticketing-dropdown1-widgets/hw-ticketing-dropdown1-widgets.component';
import { HwTicketHistoryChartWidgetsComponent } from '../pages/ticketing/widgets/hw-ticket-history-chart-widgets/hw-ticket-history-chart-widgets.component';
import { HwSlaPerformanceChartWidgetsComponent } from '../pages/ticketing/widgets/hw-sla-performance-chart-widgets/hw-sla-performance-chart-widgets.component';

import { HwCustomerChart1WidgeComponent } from '../pages/accounts/widgets/hw-customer-chart1-widge/hw-customer-chart1-widge.component';
import { HwCustomerChart2WidgeComponent } from '../pages/accounts/widgets/hw-customer-chart2-widge/hw-customer-chart2-widge.component';
import { HwCustomerChart3WidgeComponent } from '../pages/accounts/widgets/hw-customer-chart3-widge/hw-customer-chart3-widge.component';
import { HwCustomerChart4WidgeComponent } from '../pages/accounts/widgets/hw-customer-chart4-widge/hw-customer-chart4-widge.component';
import { PartnerChart1WidgetComponent } from '../pages/partners/widgets/partner-chart1-widget/partner-chart1-widget.component';
import { PartnerChart2WidgetComponent } from '../pages/partners/widgets/partner-chart2-widget/partner-chart2-widget.component';
import { PartnerChart3WidgetComponent } from '../pages/partners/widgets/partner-chart3-widget/partner-chart3-widget.component';
import { PartnerChart4WidgetComponent } from '../pages/partners/widgets/partner-chart4-widget/partner-chart4-widget.component';
import { PartnerSalesHistoryWidgetComponent } from '../pages/partners/widgets/partner-sales-history-widget/partner-sales-history-widget.component';

//Prospects
import { HotProspectsWidgetComponent } from '../pages/prospects/widgets/hot-prospects-widget/hot-prospects-widget.component';

import { PartnerOpenQuotesWidgetComponent } from '../pages/partners/widgets/partner-open-quotes-widget/partner-open-quotes-widget.component';
import { ServiceViewUsagechartWeidgetComponent } from '../pages/accounts/account/products-services/services/service-view/weidgets/service-view-usagechart-weidget/service-view-usagechart-weidget.component';

import { PartnerCommissionsChartWidgetComponent } from '../pages/finance/widgets/partner-commissions-chart-widget/partner-commissions-chart-widget.component';
import { RecentOrderChartWidgetComponent } from '../pages/finance/widgets/recent-order-chart-widget/recent-order-chart-widget.component';
import { TopbarChatComponent } from '../pages/chat/topbar-chat/topbar-chat.component';

@NgModule({
  declarations: [
    ScrollTopComponent,
    NoticeComponent,
    ActionNotificationComponent,
    DeleteEntityDialogComponent,
    FetchEntityDialogComponent,
    UpdateStatusDialogComponent,
    AlertComponent,

    // topbar components
    ContextMenu2Component,
    ContextMenuComponent,
    QuickPanelComponent,
    QuickUserPanelComponent,
    ScrollTopComponent,
    SearchResultComponent,
    SplashScreenComponent,
    StickyToolbarComponent,
    Subheader1Component,
    Subheader2Component,
    Subheader3Component,
    SubheaderSearchComponent,
    LanguageSelectorComponent,
    NotificationComponent,
    QuickActionComponent,
    SearchDefaultComponent,
    SearchDropdownComponent,
    UserProfileComponent,
    UserProfile2Component,
    UserProfile3Component,
    UserProfile4Component,
    CartComponent,
    Widget1SalesStatComponent,
    Widget9RecentActivitiesComponent,
    Widget12NewUsersComponent,
    Widget7WeeklySalesComponent,
    Widget1TasksOverviewComponent,
    Widget2NewArrivalsComponent,
    Widget3NewArrivalsAuthorsComponent,
    Widget4TodoComponent,
    Widget8TrendsComponent,
    Dropdown1Component,
    Dropdown2Component,
    Dropdown3Component,
    Dropdown4Component,
    Dropdown5Component,
    Dropdown6Component,
    UserTicketsComponent,
    OverviewComponent,
    ReceivablesComponent,
    TicketsComponent,
    WeeklySalesStatusComponent,
    RecentActivitesComponent,
    SalesProgressComponent,
    Dropdown6Component,
    HwTicketsOpenWidgetComponent,
    HwServicePlanWidgetComponent,
    HwAccountBalanceWidgetComponent,
    HwRecentActivitiesWidgetComponent,
    HwBillingHistoryWidgetComponent,
    HwHelpDeskWidgetComponent,
    HwHelpDeskRecentActivitiesWidgetComponent,
    HwHelpDeskTicketsListWidgetComponent,
    HwFinanceWidgetComponent,
    HwFinanceRecentActivityWidgetComponent,
    HwFinanceBillingHistoryWidgetComponent,
    HwSalesSalesHistoryWidgetComponent,
    HwSalesProspectsWidgetComponent,
    HwSalesQuotationsWidgetComponent,
    HwSalesRecentActivityWidgetComponent,
    HwSalesWidgetComponent,
    CustomersTicketsNewWidgetComponent,
    HwAMNewCustomersWidgetComponent,
    HwAMActivePlansWidgetComponent,
    CustomersOpenQuotesWidgetComponent,
    PartnerTicketsOpenWidgetComponent,
    HwPMNewCustomersWidgetComponent,
    HwPMActivePlansWidgetComponent,
    HwPMPendingInvoicesWidgetComponent,
    HwFinanceBillingHistoryChartWidgetComponent,
    HwFinanceRevenueChartWidgetComponent,
    HwSalesCustomerGrowthChartWidgetComponent,
    HwSalesQuotationsWonChartWidgetComponent,
    HwSalesAgentPerformanceChartWidgetComponent,
    HwSalesQuotationsPipelineChartWidgetComponent,
    HwTicketingDropdown1WidgetsComponent,
    HwTicketHistoryChartWidgetsComponent,
    HwSlaPerformanceChartWidgetsComponent,
    HwCustomerChart1WidgeComponent,
    HwCustomerChart2WidgeComponent,
    HwCustomerChart3WidgeComponent,
    HwCustomerChart4WidgeComponent,
    PartnerChart1WidgetComponent,
    PartnerChart2WidgetComponent,
    PartnerChart3WidgetComponent,
    PartnerChart4WidgetComponent,
    PartnerCommissionsChartWidgetComponent,
    PartnerOpenQuotesWidgetComponent,
    PartnerSalesHistoryWidgetComponent,
    HotquotationWidgetComponent,
    ServiceViewUsagechartWeidgetComponent,
    RecentOrderChartWidgetComponent,
    HotProspectsWidgetComponent,

    TopbarChatComponent,
  ],
  exports: [
    WidgetModule,
    PortletModule,

    ScrollTopComponent,
    NoticeComponent,
    ActionNotificationComponent,
    DeleteEntityDialogComponent,
    FetchEntityDialogComponent,
    UpdateStatusDialogComponent,
    AlertComponent,

    // topbar components
    ContextMenu2Component,
    ContextMenuComponent,
    QuickPanelComponent,
    QuickUserPanelComponent,
    ScrollTopComponent,
    SearchResultComponent,
    SplashScreenComponent,
    StickyToolbarComponent,
    Subheader1Component,
    Subheader2Component,
    Subheader3Component,
    SubheaderSearchComponent,
    LanguageSelectorComponent,
    NotificationComponent,
    QuickActionComponent,
    SearchDefaultComponent,
    SearchDropdownComponent,
    UserProfileComponent,
    UserProfile2Component,
    UserProfile3Component,
    UserProfile4Component,
    CartComponent,
    Widget1SalesStatComponent,
    Widget9RecentActivitiesComponent,
    Widget12NewUsersComponent,
    Widget7WeeklySalesComponent,
    Widget1TasksOverviewComponent,
    Widget2NewArrivalsComponent,
    Widget3NewArrivalsAuthorsComponent,
    Widget4TodoComponent,
    Widget8TrendsComponent,
    Dropdown1Component,
    Dropdown2Component,
    Dropdown3Component,
    Dropdown4Component,
    Dropdown5Component,
    Dropdown6Component,
    UserTicketsComponent,
    OverviewComponent,
    ReceivablesComponent,
    TicketsComponent,
    WeeklySalesStatusComponent,
    RecentActivitesComponent,
    SalesProgressComponent,
    HwTicketsOpenWidgetComponent,
    HwServicePlanWidgetComponent,
    HwAccountBalanceWidgetComponent,
    HwRecentActivitiesWidgetComponent,
    HwBillingHistoryWidgetComponent,
    HwHelpDeskWidgetComponent,
    HwHelpDeskRecentActivitiesWidgetComponent,
    HwHelpDeskTicketsListWidgetComponent,
    HwFinanceWidgetComponent,
    HwFinanceRecentActivityWidgetComponent,
    HwFinanceBillingHistoryWidgetComponent,
    HwSalesSalesHistoryWidgetComponent,
    HwSalesProspectsWidgetComponent,
    HwSalesQuotationsWidgetComponent,
    HwSalesRecentActivityWidgetComponent,
    HwSalesWidgetComponent,
    CustomersTicketsNewWidgetComponent,
    HwAMNewCustomersWidgetComponent,
    HwAMActivePlansWidgetComponent,
    CustomersOpenQuotesWidgetComponent,
    PartnerTicketsOpenWidgetComponent,
    HwPMNewCustomersWidgetComponent,
    HwPMActivePlansWidgetComponent,
    HwPMPendingInvoicesWidgetComponent,
    HwFinanceBillingHistoryChartWidgetComponent,
    HwFinanceRevenueChartWidgetComponent,
    HwSalesCustomerGrowthChartWidgetComponent,
    HwSalesQuotationsWonChartWidgetComponent,
    HwSalesAgentPerformanceChartWidgetComponent,
    HwSalesQuotationsPipelineChartWidgetComponent,
    HwTicketingDropdown1WidgetsComponent,
    HwTicketHistoryChartWidgetsComponent,
    HwSlaPerformanceChartWidgetsComponent,
    HwCustomerChart1WidgeComponent,
    HwCustomerChart2WidgeComponent,
    HwCustomerChart3WidgeComponent,
    HwCustomerChart4WidgeComponent,
    PartnerChart1WidgetComponent,
    PartnerChart2WidgetComponent,
    PartnerChart3WidgetComponent,
    PartnerChart4WidgetComponent,
    PartnerCommissionsChartWidgetComponent,
    PartnerOpenQuotesWidgetComponent,
    PartnerSalesHistoryWidgetComponent,
    HotquotationWidgetComponent,
    ServiceViewUsagechartWeidgetComponent,
    RecentOrderChartWidgetComponent,
    HotProspectsWidgetComponent,

    TopbarChatComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    InlineSVGModule,
    CoreModule,
    PortletModule,
    WidgetModule,
    NgApexchartsModule,
    // angular material modules
    MatButtonModule,
    MatMenuModule,
    MatSelectModule,
    MatInputModule,
    MatTableModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatIconModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatCardModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatTabsModule,
    MatTooltipModule,
    MatDialogModule,

    // ng-bootstrap modules
    NgbDropdownModule,
    NgbTabsetModule,
    NgbTooltipModule,
    NgbModule,
    CKEditorModule,
  ],
})
export class PartialsModule {}
