<div class="modal-header">
    <h4 class="modal-title">{{ title }} Reminder</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
<form (ngSubmit)="onSubmit(reminderFrom)" #reminderFrom = "ngForm">
    <div class="modal-body">
            

            <div class="row">
                <div class="col-lg-6 col-md-6 col-12">
                    <div class="form-group">
                        <label>Type</label>
                        <select class="form-control form-control-lg form-control-solid" 
                            placeholder="Select"
                            name="type"
                            [(ngModel)] = 'reminder.type'
                            #typeInput="ngModel"
                            required>
                            <option value="">Select</option>
                            <option value="Follow up">Follow up</option>
                            <option value="Call reminder">Call reminder</option>
                            <option value="Reminder">Reminder</option>
                            <option value="Appointment">Appointment</option>
                            <option value="Task">Task</option>
                        </select>
        
                        <div class="inavalid-feedback" *ngIf="typeInput.invalid && typeInput.touched">
                            <small>Type is required</small>
                        </div>
                    </div> 
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                    <div class="form-group">
                        <label>Agent</label>
                        <select class="form-control form-control-lg form-control-solid" 
                        placeholder="Select"
                        name="ownerUserId"
                        [(ngModel)]="reminder.ownerUserId"
                        #ownerUserIdInput="ngModel"
                        required>
                            <option [ngValue]="null" disabled>Select</option>
                            <option [ngValue]="agent.id" *ngFor="let agent of agentList" >{{agent.name}} {{userId == agent.id ? '(me)' : ''}}</option>
                        </select>
        
                        <div class="inavalid-feedback" *ngIf="ownerUserIdInput.invalid && ownerUserIdInput.touched">
                            <small>Agent is required</small>
                        </div>
                    </div> 
                </div>
            </div>
             
            <div class="row">
                <div class="col-xl-6">
                    <div class="form-group">
                        <label >Date and time</label>
                        <div class="input-group">
                        <input class="form-control form-control-lg form-control-solid" 
                        placeholder="yyyy-mm-dd" 
                        name="reminderDate"
                        ngbDatepicker 
                        [(ngModel)] = timeStamp.date
                        #reminderDateInput="ngModel"
                        #d3="ngbDatepicker">
                        <div class="input-group-append">
                            <button class="btn btn-primary" (click)="d3.toggle()" type="button">
                                <i class="far fa-calendar-alt"></i>
                            </button>
                        </div>
                        </div>
                        <div class="inavalid-feedback" *ngIf="reminderDateInput.invalid && reminderDateInput.touched">
                            <small>Date is required</small>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6">
                    <div class="m-t10">
                        <ngb-timepicker 
                        name="addReminderTime"
                        [(ngModel)] = timeStamp.time
                        [meridian]="meridian" >
                    </ngb-timepicker>
                        
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label for="">Description</label>
                <textarea 
                    class="form-control form-control-lg form-control-solid" 
                    rows="5"
                    name="description"
                    [(ngModel)] = "reminder.description"
                    #descriptionInput="ngModel"
                    required>
                </textarea>
                <div class="inavalid-feedback" *ngIf="descriptionInput.invalid && descriptionInput.touched">
                    <small>Description is required</small>
                </div>
            </div>

            <div class="form-group" *ngIf="mode === 'Edit'">
                <div class="checkbox-inline">
                    <label class="checkbox">
                        <input type="checkbox" 
                        name="Checkboxes2"
                        checked
                        [(ngModel)]="reminder.completed"/>
                        <span></span>
                        Task completed
                    </label>
                </div>
            </div>




    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-sm" (click)="activeModal.close('Close click')">Close</button>
        <button type="submit" 
        class="btn btn-primary btn-sm"
        [disabled] ="reminderFrom.invalid">Save</button>
    </div>
</form>
