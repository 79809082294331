import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { CoreModule } from '../../../core/core.module';
import { NgbDropdownModule, NgbTabsetModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { SharedContactsListComponent } from './contacts-list/contacts-list.component';
import { SharedAddressListComponent } from './address-list/address-list.component';
import { SharedTicketsListComponent } from './tickets-list/tickets-list.component';
import { SharedQuoteListComponent } from './quote-list/quote-list.component';
import { SharedOrderListComponent } from './order-list/order-list.component';
import { SharedProspectsListComponent } from './prospects-list/prospects-list.component';

// Material
import { A11yModule } from '@angular/cdk/a11y';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { PartialsModule } from '../../partials/partials.module';

import { ReminderComponent } from './reminder/reminder.component';

//File upload
import { NgxDropzoneModule } from 'ngx-dropzone';
import { OrderDetailsComponent } from './order-details/order-details.component';

import { SharedReminderListComponent } from './reminder-list/reminder-list.component';
import { SharedDocumentListComponent } from './document-list/document-list.component';
import { SharedNoteListComponent } from './note-list/note-list.component';
import { OrderDraftComponent } from './order-draft/order-draft.component';

import { ModalContactAddComponent } from './modal-contact-add/modal-contact-add.component';
import { ModalAddressAddComponent } from './modal-address-add/modal-address-add.component';
import { ModalDocumentComponent } from './modal-document/modal-document.component';
import { ModalSendToPartnerComponent } from './modal-send-to-partner/modal-send-to-partner.component';
import { ModalSendToCustomerComponent } from './modal-send-to-customer/modal-send-to-customer.component';
import { ModalDeleteComponent } from './modal-delete/modal-delete.component';
import { ModalSendInvoiceComponent } from './modal-send-invoice/modal-send-invoice.component';
import { ReminderPopupComponent } from './reminder-popup/reminder-popup.component';
import { SharedCommissionsListComponent } from './commissions-list/commissions-list.component';
import { CustomToast } from './custom-toastr/custom-toastr';
import { HotquotationWidgetComponent } from './hotquotation-widget/hotquotation-widget.component';

@NgModule({
  declarations: [
    SharedContactsListComponent,
    SharedAddressListComponent,
    SharedTicketsListComponent,
    SharedQuoteListComponent,
    SharedOrderListComponent,
    SharedProspectsListComponent,
    SharedReminderListComponent,
    SharedDocumentListComponent,
    SharedNoteListComponent,
    ReminderComponent,
    ModalDocumentComponent,
    CustomToast,

    ModalContactAddComponent,
    ModalAddressAddComponent,
    OrderDetailsComponent,
    ModalSendToPartnerComponent,
    ModalSendToCustomerComponent,
    OrderDraftComponent,
    ModalDeleteComponent,
    ModalSendInvoiceComponent,
    ReminderPopupComponent,
    SharedCommissionsListComponent,
  ],
  imports: [
    CommonModule,
    PartialsModule,
    CoreModule,
    // ng-bootstrap modules
    NgbDropdownModule,
    NgbTabsetModule,
    NgbTooltipModule,

    A11yModule,
    ClipboardModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    PortalModule,
    ScrollingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    InlineSVGModule,
    NgSelectModule,
    NgOptionHighlightModule,
    RouterModule,
    NgxDropzoneModule,
    CKEditorModule,
  ],
  entryComponents: [
    ReminderComponent,
    ModalDocumentComponent,
    ModalContactAddComponent,
    ModalAddressAddComponent,
    ModalSendToPartnerComponent,
    ModalSendToCustomerComponent,
    ModalDeleteComponent,
    ReminderPopupComponent,
    CustomToast,
  ],

  exports: [
    HotquotationWidgetComponent,
    SharedContactsListComponent,
    SharedAddressListComponent,
    SharedTicketsListComponent,
    SharedQuoteListComponent,
    SharedOrderListComponent,
    SharedProspectsListComponent,
    SharedReminderListComponent,
    SharedDocumentListComponent,
    SharedNoteListComponent,
    SharedCommissionsListComponent,
  ],
})
export class SharedModule {}
