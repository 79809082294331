<!-- <div class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 ml-auto mr-auto"> -->

  <div class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative p-7 ml-auto mr-auto">

  <!--begin::Content body-->
  <div class="d-flex flex-column-fluid flex-center mt-6 mt-lg-0">
    <!--begin::Signin-->
    <div class="login-form login-signin">
      <!--begin::Form-->
      <form class="form" novalidate="novalidate" id="kt_login_signin_form" [formGroup]="loginForm">
        <!--begin::Title-->
        <!-- <div class="pt-lg-10 mt-lg-10 pb-15">
          <h3 class="font-weight-bolder text-dark display5">Sign in</h3>
        </div> -->
        <div class=" pb-15">
          <h3 class="font-weight-bolder text-dark display5">Sign in</h3>
        </div>
        <!--begin::Title-->
        <!--begin::Form group-->
        <div class="form-group sits-formGroup">
          <label class="font-size-h6 font-weight-bolder text-dark">Email</label>
          <input class="form-control form-control-solid h-auto rounded-lg" type="email" name="username" autocomplete="off" formControlName="email" />
        </div>
        <!--end::Form group-->
        <!--begin::Form group-->
        <div class="form-group sits-formGroup">
          <div class="d-flex justify-content-between mt-n5">
            <label class="font-size-h6 font-weight-bolder text-dark pt-5">Password</label>
          </div>
          <input class="form-control form-control-solid h-auto rounded-lg" type="password" name="password" autocomplete="off" formControlName="password"/>
        </div>
        <!--end::Form group-->
        <!--begin::Action-->
        <div class="pb-lg-0 pb-10">
          <button type="button" id="kt_login_signin_submit" class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3" (click)="submit()">Sign In</button>
        </div>
        <!--end::Action-->
      </form>
      <!--end::Form-->
    </div>
    <!--end::Signin-->
  </div>
  <!--end::Content body-->


  <!--end::Content footer-->
</div>
