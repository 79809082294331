import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LogixapiService } from './logixapi.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReminderPopupComponent } from '../views/pages/shared/reminder-popup/reminder-popup.component';
import { Reminders } from './reminder.service';
import { CustomToast } from '../views/pages/shared/custom-toastr/custom-toastr';
import { GlobalConfig, ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class TicketNotificationService {
  private readonly toastrDefaults = {
    timeOut: 5000,
    closeButton: true,
    enableHtml: true,
    tapToDismiss: true,
    titleClass: '',
    positionClass: 'toast-top-right-extra',
    context: undefined,
    extendedTimeOut: 0,
  };
  config = Object.assign({}, this.toastrDefaults);

  options: any;
  toastrOptions: GlobalConfig;
  private lastInserted: number[] = [];
  public connected = false;

  constructor(private logixapi: LogixapiService, public toastr: ToastrService) {
    this.options = this.toastr.toastrConfig;

    this.subscribeToTicketEvents();
  }

  subscribeToTicketEvents() {
    console.log('Subscribing to ticket notifications');
    this.logixapi.subscribeTo('event.ticket.new', (event) => {
      this.displayTicketNewNotification(event);
    });

    this.logixapi.subscribeTo('event.ticket.reply', (event) => {
      this.displayTicketReplyNotification(event);
    });
  }

  displayTicketReplyNotification(event: any) {
    const notificationObj = {
      title: 'Ticket Reply',
      event: event.event,
      message: event.subject,
      ticketId: event.ticketId,
      fromName: event.fromName || false,
      fromEmail: event.fromEmail || false,
      status: event.status || null,
      priority: event.prority || null,
    };

    this.openTicketToast(notificationObj);
  }

  displayTicketNewNotification(event: any) {
    const notificationObj = {
      event: event.event,
      title: 'New Ticket',
      message: event.subject,
      ticketId: event.ticketId,
      fromName: event.fromName || false,
      fromEmail: event.fromEmail || false,
      status: event.status || null,
      priority: event.prority || null,
    };

    this.openTicketToast(notificationObj);
  }

  handleNotification(event: any) {
    console.log(event);
  }

  openTicketToast(notification: any) {
    const audio = new Audio('/assets/alert.wav');
    audio.play();

    const opt = this.options; //this.toastrOptions;
    opt.toastComponent = CustomToast;
    //opt.toastClass = '';
    opt.closeButton = false;
    opt.tapToDismiss = false;
    opt.positionClass = 'toast-top-right-extra';
    opt.toastClass = 'toast';
    opt.timeOut = 0;
    opt.extendedTimeOut = 0;
    opt.ticketId = notification.ticketId;
    opt.notification = notification;

    const { message, title } = {
      message: 'Phone is not working after restarting the device',
      title: 'New Ticket',
    };

    const inserted = this.toastr.show(notification.message, notification.title, opt);

    inserted.onAction.subscribe((event) => {
      if (event === 'close') this.toastr.clear(inserted.toastId);
    });
    if (inserted && inserted.toastId) {
      this.lastInserted.push(inserted.toastId);
    }
    return inserted;
  }
}
