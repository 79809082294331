
    <div
      class="toast {{
        options.notification.status ? 'toast-border-ticket-' + options.notification.status.toLowerCase() : ''
      }}"
      role="alert"
      style="opacity: 1; !important; background-color: white; width:320px; border-left-width: 4px; border-left-style: solid; font-size: 0.97rem;"
    >
      <div class="toast-header" style="border-bottom: none;">
        <strong class="me-auto">{{ title || 'default header' }}</strong>
        <div
          style="
    float: right;
    position: absolute;
    right: 10px;
"
        >
          <i class="flaticon2-delete icon-nm cursor-pointer" (click)="close()"></i>
        </div>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          *ngIf="options.closeButton"
          (click)="close()"
        ></button>
      </div>
      <div class="toast-body cursor-pointer">
        <div (click)="openTicket()" role="alert" aria-live="polite" [attr.aria-label]="message">{{ message }}</div>
      </div>
    </div>
  