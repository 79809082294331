import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LogixapiService } from '../../../../services/logixapi.service';

@Component({
  selector: 'kt-modal-send-to-partner',
  templateUrl: './modal-send-to-partner.component.html',
  styleUrls: ['./modal-send-to-partner.component.scss']
})
export class ModalSendToPartnerComponent implements OnInit {
  partnerList: string[];
  titleFunction='Transaction';
  partners:[];

  constructor(public activeModal: NgbActiveModal, private logixapi: LogixapiService) { }

  ngOnInit(): void {
    this.loadCustomerList().then(() => {});
  }

  onSubmit(fromInfo){

  }

  async loadCustomerList() {
    const partners = await this.logixapi.get(`/Partners`, null).toPromise();
    this.partners = partners;

    console.log('Partner list'+this.partners);
  }

}
