<!--begin::Subheader-->
<div class="subheader py-2 py-lg-4" [ngClass]="subheaderClasses" id="kt_subheader">
	<div [ngClass]="subheaderContainerClasses" class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
    <!--begin::Info-->
    <div class="d-flex align-items-center flex-wrap mr-2">
      <!--begin::Page Title-->

      <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">
				{{title}}
			</h5>
      <!--end::Page Title-->

      <!--begin::Action-->
      <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"></div>
      <span class="text-muted font-weight-bold mr-4">#XRS-45670</span>
      <a href="#" class="btn btn-light-primary font-weight-bolder btn-sm">Add New</a>
      <!--end::Action-->
    </div>
		<!-- end:: Info -->

		<!-- begin:: Toolbar -->
    <div class="d-flex align-items-center">
      <ng-container *ngIf="displayDaterangepicker">
        <!--begin::Daterange-->
        <a href="#" class="btn btn-light btn-sm font-weight-bold mr-2" id="kt_dashboard_daterangepicker"
           data-toggle="tooltip" title="Select dashboard daterange" data-placement="left">
				<span class="text-muted font-weight-bold"
              id="kt_dashboard_daterangepicker_title mr-2">Today</span>&nbsp;
          <span class="text-primary font-weight-bold" id="kt_dashboard_daterangepicker_date">Aug 16</span>
        </a>
        <!--end::Daterange-->
      </ng-container>

      <!--begin::Actions-->
      <a href="#" class="btn btn-light btn-hover-primary btn-sm btn-icon mr-2">
        <i class="flaticon2-file icon-md text-muted"></i>
      </a>

      <a href="#" class="btn btn-light btn-hover-primary btn-sm btn-icon mr-2">
        <i class="flaticon-download-1 icon-md text-muted"></i>
      </a>

      <a href="#" class="btn btn-light btn-hover-primary btn-sm btn-icon mr-2">
        <i class="flaticon2-fax icon-md text-muted"></i>
      </a>

      <a href="#" class="btn btn-light btn-hover-primary btn-sm btn-icon mr-2">
        <i class="flaticon2-settings icon-md text-muted"></i>
      </a>
      <!--end::Actions-->

			<div ngbDropdown [placement]="'bottom-right'" class="dropdown dropdown-inline" ngbTooltip="Quick actions">
				<a ngbDropdownToggle href="javascript:;" class="btn btn-icon">
          <span class="svg-icon svg-icon-success svg-icon-2x" [inlineSVG]="'./assets/media/svg/icons/Files/File-plus.svg'"></span>
        </a>
				<div ngbDropdownMenu class="dropdown-menu dropdown-menu-fit dropdown-menu-md dropdown-menu-right">
          <!--begin::Navigation-->
          <ul class="navi navi-hover">
            <li class="navi-header font-weight-bold py-5">
              <span class="font-size-lg">Choose Label:</span>
              <i class="flaticon2-information icon-md text-muted" data-toggle="tooltip" data-placement="right" title="Click to learn more..."></i>
            </li>
            <li class="navi-separator mb-3 opacity-70"></li>
            <li class="navi-item">
              <a href="#" class="navi-link">
            <span class="navi-text">
                <span class="label label-xl label-inline label-light-success">Customer</span>
            </span>
              </a>
            </li>
            <li class="navi-item">
              <a href="#" class="navi-link">
            <span class="navi-text">
                <span class="label label-xl label-inline label-light-danger">Partner</span>
            </span>
              </a>
            </li>
            <li class="navi-item">
              <a href="#" class="navi-link">
            <span class="navi-text">
                <span class="label label-xl label-inline label-light-warning">Suplier</span>
            </span>
              </a>
            </li>
            <li class="navi-item">
              <a href="#" class="navi-link">
            <span class="navi-text">
                <span class="label label-xl label-inline label-light-primary">Member</span>
            </span>
              </a>
            </li>
            <li class="navi-item">
              <a href="#" class="navi-link">
            <span class="navi-text">
                <span class="label label-xl label-inline label-light-dark">Staff</span>
            </span>
              </a>
            </li>
            <li class="navi-separator mt-3 opacity-70"></li>
            <li class="navi-footer pt-5 pb-4">
              <a class="btn btn-clean font-weight-bold btn-sm" href="#">
                <i class="ki ki-plus icon-sm"></i>
                Add new
              </a>
            </li>
          </ul>
          <!--end::Navigation-->
				</div>
			</div>
		</div>
		<!-- end:: Toolbar -->
	</div>
</div>
<!--end::Subheader-->
