import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  private readonly _order = new BehaviorSubject<Order>(null);
  readonly order$ = this._order.asObservable();

  public get order(): Order {
    return this._order.getValue();
  }

  public set order(val: Order) {
    this._order.next(val);
  }


  constructor() { }
}

export interface Order {
  id: number;
  status: string;
  OrderLines: {};
}
