// Anglar
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// Layout Directives
// Services
import {
  ContentAnimateDirective,
  FirstLetterPipe,
  GetObjectPipe,
  HeaderDirective,
  JoinPipe,
  MenuDirective,
  OffcanvasDirective,
  SafePipe,
  ScrollTopDirective,
  SparklineChartDirective,
  StickyDirective,
  TabClickEventDirective,
  TimeElapsedPipe,
  ToggleDirective,
} from './_base/layout';

import { ContactNamePipe } from '../pipes/contact-name.pipe';
import { TimeAgoPipe } from '../pipes/time-ago.pipe';
import { TicketstatusTimeAgoPipe } from '../pipes/ticketstatus-time-ago.pipe';
import { OrderStatusPipe } from '../pipes/order-status.pipe';
import { TicketStatusPipe } from '../pipes/ticket-status.pipe';
import { ProspectRatingPipe } from '../pipes/prospect-rating.pipe';
import { TicketStatusTextColorPipe } from '../pipes/ticket-status-text-color.pipe';
import { PriorityStatusTextColorPipe } from '../pipes/priority-status-text-color.pipe';
import { PhoneNumberPipe } from '../pipes/phone-number.pipe';
import { ServiceStatusPipe } from '../pipes/service-status.pipe';
import { QuoteStatusPipe } from '../pipes/quote-status.pipe';
import { TruncatePipe } from '../pipes/truncate.pipe';
import { ProspectStatusClassPipe } from '../pipes/prospect-status-class.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    // directives
    ScrollTopDirective,
    HeaderDirective,
    OffcanvasDirective,
    ToggleDirective,
    MenuDirective,
    TabClickEventDirective,
    SparklineChartDirective,
    ContentAnimateDirective,
    StickyDirective,
    // pipes
    TimeElapsedPipe,
    TicketstatusTimeAgoPipe,
    JoinPipe,
    GetObjectPipe,
    SafePipe,
    FirstLetterPipe,
    ContactNamePipe,
    PhoneNumberPipe,
    ServiceStatusPipe,
    OrderStatusPipe,
    TicketStatusPipe,
    ProspectRatingPipe,
    ProspectStatusClassPipe,
    TicketStatusTextColorPipe,
    PriorityStatusTextColorPipe,
    TimeAgoPipe,
    QuoteStatusPipe,
    TruncatePipe,
  ],
  exports: [
    // directives
    ScrollTopDirective,
    HeaderDirective,
    OffcanvasDirective,
    ToggleDirective,
    MenuDirective,
    TabClickEventDirective,
    SparklineChartDirective,
    ContentAnimateDirective,
    StickyDirective,
    // pipes
    TimeElapsedPipe,
    JoinPipe,
    GetObjectPipe,
    SafePipe,
    FirstLetterPipe,
    ContactNamePipe,
    PhoneNumberPipe,
    ServiceStatusPipe,
    OrderStatusPipe,
    TicketStatusPipe,
    ProspectRatingPipe,
    ProspectStatusClassPipe,
    TicketStatusTextColorPipe,
    PriorityStatusTextColorPipe,
    TimeAgoPipe,
    TicketstatusTimeAgoPipe,
    QuoteStatusPipe,
    TruncatePipe,
  ],
  providers: [],
})
export class CoreModule {}
