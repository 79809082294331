import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { LogixapiService } from 'src/app/services/logixapi.service';
import { ReminderService } from '../../../../services/reminder.service';
import * as moment from 'moment';
import { Router } from '@angular/router';

@Component({
  selector: 'kt-reminder-popup',
  templateUrl: './reminder-popup.component.html',
  styleUrls: ['./reminder-popup.component.scss'],
})
export class ReminderPopupComponent implements OnInit {
  reminder$ = this.reminderStore.reminders$;

  reminder: any = {};
  timeStamp: any = {};
  postponeBy: any = null;
  userId: any = null;

  constructor(
    private router: Router,
    private logixapi: LogixapiService,
    private toastr: ToastrService,
    public activeModal: NgbActiveModal,
    private reminderStore: ReminderService
  ) {}

  ngOnInit(): void {
    this.userId = this.logixapi.getUser().userId;
    console.log('this.reminder on popup', this.reminder);
  }
  async openCustomer() {
    // Postpone by 10 minutes
    let startDatetime = this.reminder.startDatetime;

    let a = moment(startDatetime);
    let b = a.add(10, 'm');

    this.reminder.startDatetime = b;

    try {
      await this.logixapi.put(`/Reminders/${this.reminder.id}`, this.reminder).toPromise();
      await this.fnGetRemiderList();

      //this.showToasterSuccess('Reminder updated or completed successfully', '');
      this.router.navigate([`/accounts/${this.reminder.customerId}/overview`]);
      this.activeModal.close(true);
    } catch (err) {
      this.showToasterError('Someting wrong', '');
    }
  }

  async openProspect() {
    // Postpone by 10 minutes
    let startDatetime = this.reminder.startDatetime;

    let a = moment(startDatetime);
    let b = a.add(10, 'm');

    this.reminder.startDatetime = b;

    try {
      await this.logixapi.put(`/Reminders/${this.reminder.id}`, this.reminder).toPromise();
      await this.fnGetRemiderList();

      //this.showToasterSuccess('Reminder updated or completed successfully', '');
      this.router.navigate([`/sales/prospect/${this.reminder.prospectId}/overview`]);
      this.activeModal.close(true);
    } catch (err) {
      this.showToasterError('Someting wrong', '');
    }
  }

  async openTicket() {
    // Postpone by 10 minutes
    let startDatetime = this.reminder.startDatetime;

    let a = moment(startDatetime);
    let b = a.add(10, 'm');

    this.reminder.startDatetime = b;

    try {
      await this.logixapi.put(`/Reminders/${this.reminder.id}`, this.reminder).toPromise();
      await this.fnGetRemiderList();

      //this.showToasterSuccess('Reminder updated or completed successfully', '');
      this.router.navigate([`/ticketing/ticket/${this.reminder.ticketId}`]);
      this.activeModal.close(true);
    } catch (err) {
      this.showToasterError('Someting wrong', '');
    }

    // navigate to ticket
  }

  async fnMakePostponeOrComplete() {
    if (this.reminder.completed === true) {
      this.reminder.completedByUserId = this.userId;
      this.reminder.completedDatetime = moment().format();
    } else {
      let startDatetime = this.reminder.startDatetime;
      this.reminder.completed = false;
      this.reminder.completedByUserId = null;
      this.reminder.completedDatetime = null;

      // let dateString = this.timeStamp.date.year +'-'+ this.timeStamp.date.month +'-'+ this.timeStamp.date.day;
      // let timeString = this.timeStamp.time.hour + ':' + this.timeStamp.time.minute + ':00';
      // let dateObj = new Date(dateString + ' ' + timeString);
      // let startDatetime = moment(dateObj).format();

      // this.reminder.startDatetime = startDatetime;
      // this.reminder.timezone = moment(startDatetime).format("Z");

      // this.reminder.createdByUserId = this.userId;

      let postponeBy = this.postponeBy;
      let unit = postponeBy.split(' ');
      let vlaueTime = unit[0];
      let unitType = unit[1];
      // 5 minutes
      // 10 minutes
      // 15 minutes
      // 20 minutes
      // 25 minutes
      // 30 minutes
      // 1 hour
      // 2 hours
      // 4 hours
      // 8 hours
      // 1 day
      // 2 days
      // 3 days
      // 4 days
      // 1 week
      // 2 week

      if (unitType === 'minutes') {
        let a = moment(startDatetime);
        let b = a.add(vlaueTime, 'm');

        this.reminder.startDatetime = b;
      } else if (unitType === 'hour' || unitType === 'hours') {
        let a = moment(startDatetime);
        let b = a.add(vlaueTime, 'm');

        this.reminder.startDatetime = b;
      } else if (unitType === 'day' || unitType === 'days') {
        let a = moment(startDatetime);
        let b = a.add(vlaueTime, 'd');
        this.reminder.startDatetime = b;
      } else if (unitType === 'week') {
        let a = moment(startDatetime);
        let b = a.add(vlaueTime, 'w');
        this.reminder.startDatetime = b;
      }
    }

    try {
      await this.logixapi.put(`/Reminders/${this.reminder.id}`, this.reminder).toPromise();
      await this.fnGetRemiderList();

      this.showToasterSuccess('Reminder updated or completed successfully', '');
      this.activeModal.close(true);
    } catch (err) {
      this.showToasterError('Someting wrong', '');
    }
  }

  async fnGetRemiderList() {
    let whereCondition = {
      ownerUserId: this.userId,
      completed: false,
    };

    let remiders = await this.logixapi.get(`/Reminders/`, { where: whereCondition }).toPromise();

    this.reminderStore.reminders = remiders;

    return (this.reminderStore.reminders = remiders);
  }

  onSubmit(reminderFrom) {
    this.fnMakePostponeOrComplete();
  }

  showToasterSuccess(message, title) {
    this.toastr.success(message, title);
  }

  showToasterError(message, title) {
    this.toastr.error(message, title);
  }
}
