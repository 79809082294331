import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LogixapiService } from '../../../../services/logixapi.service';

@Component({
  selector: 'kt-modal-contact-add',
  templateUrl: './modal-contact-add.component.html',
  styleUrls: ['./modal-contact-add.component.scss'],
})
export class ModalContactAddComponent implements OnInit {
  @Input() public contact;
  @Input() public mode;
  @Input() public type;

  @Input() public customerId;
  @Input() public prospectId;
  @Input() public partnerId;

  constructor(public activeModal: NgbActiveModal, private logixapi: LogixapiService) {}

  contactModalForm: FormGroup;
  titleFunction = 'Add';
  errorMessage: string;

  ngOnInit(): void {
    if (!this.contact) this.contact = {};

    this.contactModalForm = new FormGroup({
      personTitle: new FormControl(this.contact.personTitle, Validators.required),
      title: new FormControl(this.contact.title, Validators.required),
      firstName: new FormControl(this.contact.firstName, Validators.required),
      lastName: new FormControl(this.contact.lastName),
      position: new FormControl(this.contact.position),
      phone: new FormControl(this.contact.phone, [Validators.required]),
      mobile: new FormControl(this.contact.mobile, [Validators.required]),
      email: new FormControl(this.contact.email, [Validators.required, Validators.email]),
      gender: new FormControl(this.contact.gender),
      dob: new FormControl(this.contact.dob),
      location: new FormControl(null),
      billingNotification: new FormControl(this.contact.billingNotification),
      accountNotification: new FormControl(this.contact.accountNotification),
      technicalNotification: new FormControl(this.contact.technicalNotification),
      salesNotification: new FormControl(this.contact.salesNotification),
    });

    if (this.mode === 'edit') this.contactModalForm.markAllAsTouched();
  }

  get personTitle() {
    return this.contactModalForm.get('personTitle');
  }
  get title() {
    return this.contactModalForm.get('title');
  }
  get firstName() {
    return this.contactModalForm.get('firstName');
  }
  get phone() {
    return this.contactModalForm.get('phone');
  }
  get mobile() {
    return this.contactModalForm.get('mobile');
  }
  get email() {
    return this.contactModalForm.get('email');
  }

  async onSubmit() {
    if (this.contactModalForm.valid) {
      let apiUrl = this.buildApiUrl();

      let result;

      try {
        if (this.mode === 'add') {
          result = await this.logixapi.post(apiUrl, this.contactModalForm.value).toPromise();
        } else if (this.mode === 'edit') {
          result = await this.logixapi.put(apiUrl, this.contactModalForm.value).toPromise();
        }
      } catch (err) {
        if (err.error && err.error.error) this.setError(err.error.error.message);
      }

      if (result) {
        this.activeModal.close(true);
      }
    }
  }

  setError(message: string) {
    this.errorMessage = message;

    setTimeout(() => {
      this.errorMessage = null;
    }, 5000);
  }

  buildApiUrl() {
    let apiUrl = '';

    if (this.customerId) {
      apiUrl = `/Customers/${this.customerId}/Contacts`;
    } else if (this.partnerId) {
      apiUrl = `/Partners/${this.partnerId}/Contacts`;
    } else if (this.prospectId) {
      apiUrl = `/Prospects/${this.prospectId}/Contacts`;
    }

    if (this.mode === 'edit') {
      apiUrl = `${apiUrl}/${this.contact.id}`;
    }

    return apiUrl;
  }

  // Update existing contact
  save() {}

  // Add new contact
  add() {}
}
