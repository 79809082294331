  <!-- Model shows Add / view / Edit Address information -->
  <div>
    <div class="modal-header">
      <h4 class="modal-title">Address - {{ titleFunction }}</h4>

      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>

    </div>
    <div class="modal-body" [formGroup]="addressModalForm">
      <div class="row">
        <div class="col-xl-1"></div>
        <div class="col-xl-8 my-2">
          <div class="form-group row">
            <label class="col-form-label col-4 text-lg-right text-left">Address type</label>
            <div class="col-8">
              <select class="form-control form-control-lg form-control-solid"
              id="type"
              placeholder="Select"
                      formControlName="type" >
                <option >Select</option>
                    <option value="Office">Office</option>
                    <option value="Head office">Head office</option>
                    <option value="Branch office">Branch office</option>
                    <option value="PO Box">PO Box</option>
                    <option value="Delivery">Delivery</option>
                    <option value="Home">Home</option>
              </select>
            </div>
          </div>


          <!-- Current-address -->
          <div *ngIf="mode == 'edit'">
            <div class="form-group row">
              <label class="col-form-label col-4 text-lg-right text-left">Current address</label>
              <div class="col-8">
                <textarea class="form-control form-control-lg form-control-solid"
                type="text"
                placeholder="Address 1"
                formControlName="currentFullAddress" disabled></textarea>
              </div>
            </div>
          </div>

          <div class="form-group row" *ngIf="!manualAddress && mode == 'add'">
            <label class="col-form-label col-4 text-lg-right text-left">Address</label>
            <div class="col-8">
              <input  type="text" class="form-control" [ngModelOptions]="{standalone: true}" (selectItem)="selectedAddress($event)" [class.is-invalid]="searchFailed" [(ngModel)]="model" [inputFormatter]='formatAddressSearch' [resultFormatter]='formatAddressSearch'
                     [ngbTypeahead]="search" placeholder="Address search" />
              <span *ngIf="searching">searching...</span>
              <div class="invalid-feedback" *ngIf="searchFailed">Sorry, addresses could not be loaded.</div>
            </div>
          </div>

          <div class="form-group row" *ngIf="!manualAddress && mode == 'edit'">
            <label class="col-form-label col-4 text-lg-right text-left">Address</label>
            <div class="col-8">
              <input  type="text" class="form-control" [ngModelOptions]="{standalone: true}" (selectItem)="selectedAddress($event)" [class.is-invalid]="searchFailed" [(ngModel)]="model" [inputFormatter]='formatAddressSearch' [resultFormatter]='formatAddressSearch'
                     [ngbTypeahead]="search" placeholder="Address search" autocomplete="new-address" />
              <span *ngIf="searching">searching...</span>
              <div class="invalid-feedback" *ngIf="searchFailed">Sorry, addresses could not be loaded.</div>
            </div>
          </div>

         






         
          <div *ngIf="manualAddress && mode == 'edit' || manualAddress && mode == 'add'">
            <div class="form-group row">
              <label class="col-form-label col-4 text-lg-right text-left">Address 1</label>
              <div class="col-8">
                <input class="form-control form-control-lg form-control-solid"
                type="text"
                placeholder="Address 1"
                formControlName="addressLine1">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-4 text-lg-right text-left">Address 2</label>
              <div class="col-8">
                <input class="form-control form-control-lg form-control-solid"
                type="text"
                placeholder="Address 2"
                formControlName="addressLine2">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-4 text-lg-right text-left">City</label>
              <div class="col-8">
                <input class="form-control form-control-lg form-control-solid"
                type="text"
                placeholder="City"
                       formControlName="localityName">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-4 text-lg-right text-left">State</label>
              <div class="col-8">
                <select class="form-control form-control-lg form-control-solid"
                  id="state"
                  placeholder="State"
                  formControlName="stateTerritory">
                  <option value="">Select</option>
                  <option value="VIC">VIC</option>
                  <option value="VIC">NSW</option>
                  <option value="ACT">ACT</option>
                  <option value="QLD">QLD</option>
                  <option value="TAS">TAS</option>
                  <option value="WA">WA</option>
                  <option value="SA">SA</option>
                  <option value="NT">NT</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-4 text-lg-right text-left">Postcode</label>
              <div class="col-8">
                  <input type="text" class="form-control form-control-lg form-control-solid"
                    placeholder="Postcode"
                    formControlName="postcode">
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary btn-sm" (click)="onSubmit()" [disabled]="!address.addressLine1">Save</button>
      <button type="button" class="btn btn-secondary btn-sm" (click)="activeModal.close('Close click')">Close</button>
    </div>
</div>

