  <!-- Model shows Add / view / Edit document and notesinformation -->
  <div>
    <div class="modal-header">
      <h4 class="modal-title">Document - {{ titleFunction }}</h4>

      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>

    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-xl-12 my-2">
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label class="col-form-label text-lg-right text-left">Title</label>
                            <input class="form-control form-control-lg form-control-solid" type="text" placeholder="Title">
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label class="col-form-label text-lg-right text-left">Department</label>
                            <select class="form-control form-control-lg form-control-solid" id="title" placeholder="Select">
                                <option value="">Select</option>
                                <option value="Support">Support</option>
                                <option value="Sales">Sales</option>
                                <option value="Account">Accounts</option>
                            </select>
                        </div>
                    </div>
        
                </div>

                <div class="form-group">
                    <label class="col-form-label text-lg-right text-left">Files</label>
                    <ngx-dropzone (change)="onSelect($event)">
                        <ngx-dropzone-label>Drag & drop here</ngx-dropzone-label>
                        <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                        </ngx-dropzone-preview>
                    </ngx-dropzone>
                </div>   
            </div>
        </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary btn-sm" (click)="onSubmit()">Save</button>
      <button type="button" class="btn btn-secondary btn-sm" (click)="activeModal.close('Close click')">Close</button>
    </div>
</div>
