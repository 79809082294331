<div class="modal-header">
    <h4 class="modal-title">Send {{ titleFunction }} list</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
<form (ngSubmit)="onSubmit(reminderFrom)" #reminderFrom = "ngForm">
    <div class="modal-body">

        <!-- <div class="form-group">
            <label>Select partner</label>
            <ng-select [items]="partners"
                placeholder="Select partner"
                bindLabel="name"
                bindValue="id"
                name="selectedPartnerList"
                ngModel
                #partnerInput="ngModel"
                required>
            </ng-select>
            <div class="inavalid-feedback" *ngIf="partnerInput.invalid && partnerInput.touched">
                <small>Partner name is required</small>
            </div>
        </div> -->
        <div class="form-group">
            <label>Email address</label>
            <input type="text" 
            class="form-control form-control-solid form-control-lg" 
            placeholder="Receiver email address"
            name="receiverEmailAddress"
            ngModel
            #receiverEmailAddressInput="ngModel"
            required>

            <div class="inavalid-feedback" *ngIf="receiverEmailAddressInput.invalid && receiverEmailAddressInput.touched">
                <small>Receiver email is required</small>
            </div>

        </div>

        <div class="form-group">
            <label for="">Description</label>
            <textarea 
                class="form-control form-control-lg form-control-solid" 
                rows="3"
                name="description"
                ngModel
                #descriptionInput="ngModel"
                required>
            </textarea>
            <div class="inavalid-feedback" *ngIf="descriptionInput.invalid && descriptionInput.touched">
                <small>Description is required</small>
            </div>
        </div>


    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-sm" (click)="activeModal.close('Close click')">Close</button>
        <button type="submit" 
        class="btn btn-primary btn-sm"
        [disabled] ="reminderFrom.invalid">Send</button>
    </div>
</form>

