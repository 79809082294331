<div class="d-flex flex-column flex-root" id="kt_login_wrapper">
  <div class="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white" id="kt_login">
    <!--begin::Aside-->
    <div
      class="login-aside d-flex flex-row-auto px-lg-0 px-5 pb-sm-40 pb-lg-40 flex-grow-1 background-image-side"
      id="sits_bg_img"
      style="
        background-repeat: no-repeat !important;
        background-position: bottom !important;
        background-image: url('assets/media/img-logix/5G-Login-Graphic.png') !important;
      "
    >
      <!--begin::Aside Container-->
      <div class="d-flex flex-row-fluid flex-column mt-lg-30 mb-lg-0 pb-lg-0 mb-20 pb-40 mt-0 pt-15 h-logix-logo">
        <!--begin::Aside header-->
        <a href="#" class="text-center mb-10">
          <img src="assets/media/logos/Logix_h70px.png" class="" alt="" />
        </a>
        <!--end::Aside header-->
      </div>
      <!--end::Aside Container-->
    </div>
    <!--begin::Aside-->

    <!--begin::Content-->
    <!-- <div class="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">	 -->
    <div class="flex-row-fluid d-flex flex-column position-relative p-7">
      <router-outlet></router-outlet>
    </div>
    <!--end::Content-->
  </div>
</div>
