<!-- Model Delete -->
  <div>
    <form>
      <div class="modal-header">
        <h4 class="modal-title">{{ title }} - Delete</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
            <div class="col-12">
    
            </div>
        </div>
      </div>
      <div class="modal-footer">
        <button (click)="onSubmit()"  type="button" class="btn btn-primary btn-sm">Delete</button>
        <button type="button" class="btn btn-secondary btn-sm" (click)="activeModal.close('Close click')">Close</button>
      </div>
    </form>
  </div>


